import React from "react";
import { Col, Row } from "reactstrap";
import { TransactionWithBlock } from "../../../generated/graphql";

interface Props {
  tx: TransactionWithBlock;
}

export const MultisigMetadata: React.FC<Props> = ({ tx }) => {
  return (
    <Col md={12}>
      <Row>
        <Col md={4}>
          <h3 style={{ margin: 0 }}>Multisig details</h3>
        </Col>
      </Row>
      <Row>
        <div className={"alert alert-dark"}>
          <Col md={12}>
            <Row>
              <Col md={3}>Min keys:</Col>
              <Col>
                <strong>
                  {tx.multisigRegistration?.numberOfSignatures || 0}
                </strong>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <Col md={3}>Required Keys:</Col>
              <Col>
                <strong>[</strong>
                {tx.multisigRegistration?.mandatoryKeys?.map((k) => (
                  <div className={"ml-3"}>
                    {/*<Link to={`/account/${k}`}>*/}
                    {k}
                    {/*</Link>,*/}
                  </div>
                )) || ""}
                <strong>]</strong>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <Col md={3}>Optional keys:</Col>
              <Col>
                <strong>[</strong>
                {tx.multisigRegistration?.optionalKeys?.map((k) => (
                  <div className={"ml-3"}>
                    {/*<Link to={`/account/${k}`}>*/}
                    {k}
                    {/*</Link>,*/}
                  </div>
                )) || ""}
                <strong>]</strong>
              </Col>
            </Row>
          </Col>
        </div>
      </Row>
    </Col>
  );
};
