import React from "react";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { IsErrorOrLoading } from "../utils/IsErrorOrLoading";
import { beddowsToDecimal } from "../utils/lisk/utils/lisk/beddowsToDecimal";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { TxIcon } from "./TxIcon";
import { TXMetadata } from "./txMedatada/TxMetaData";
import {
  TransactionWithBlock,
  useBlockHeightQuery,
  useTransctionByIdQuery,
} from "../../generated/graphql";
import { Copy } from "../../UI/copy/Copy";

interface Props {}

export const TransactionDetail: React.FC<Props> = ({}) => {
  // @ts-ignore
  let { txId = "" } = useParams();
  const {
    data: txData,
    loading: txLoading,
    error: txError,
  } = useTransctionByIdQuery({
    variables: {
      id: txId,
    },
    fetchPolicy: "no-cache",
  });
  const {
    data: blockData,
    loading: blockLoading,
    error: blockError,
  }: any = useBlockHeightQuery();

  if (txLoading || txError || blockLoading || blockError) {
    return (
      <div className="content">
        <IsErrorOrLoading
          error={!!txError || !!blockError}
          title={"transactions"}
        />
      </div>
    );
  }

  const tx = txData?.transaction;
  if (!tx || !tx.id) {
    return (
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Transaction detail</CardTitle>
          </CardHeader>
          <CardBody>Oh no! No transaction found!</CardBody>
        </Card>
      </div>
    );
  }

  const blockHeight = blockData?.lastBlock?.height
    ? blockData.lastBlock.height
    : 0;
  const confirmations = blockHeight - +tx.height;
  const realRecipient = (moduleAssetId: string) => {
    switch (moduleAssetId) {
      case "2:0":
        return (
          <Link to={`/account/${tx.recipientId}`}>
            <strong>
              {tx.recipientUsername ||
                // TODO: FIX THIS
                // (tx.recipient_tag && tx.recipient_tag.tag) ||
                tx.recipientId}
            </strong>
          </Link>
        );
      case "4:0":
        return <strong>Multisig registration</strong>;
      case "5:0":
        return <strong>Delegate registration</strong>;
      case "5:1":
        return <strong>Delegate vote</strong>;
      case "5:2":
        return <strong>Token unlock</strong>;
      case "5:3":
        return <strong>Misbehavior report</strong>;
      case "1000:0":
        return <strong>Legacy address claim</strong>;
      default:
        return <strong>unsupported transaction</strong>;
    }
  };

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">Transaction detail:</CardTitle>
        </CardHeader>
        <CardBody
          style={{
            color: document.body.classList.contains("white-content")
              ? "black"
              : "#efefef",
          }}
        >
          <Row>
            <Col md={2} className={"text-center"}>
              <TxIcon
                type={tx.moduleAssetId}
                confirmations={confirmations}
                blockIsFinal={!!+tx.blockIsFinal}
              />
            </Col>
            <Col md={10}>
              <Row>
                <Col md={3}>ID:</Col>
                <Col md={3}>
                  <strong>{tx.id}</strong> <Copy text={tx.id} />
                </Col>
                <Col md={3}>Amount:</Col>
                <Col md={3}>
                  <Badge
                    className={"badge-dark"}
                    style={{ fontSize: "medium" }}
                  >
                    {
                      +beddowsToDecimal(
                        !!tx.amount ? tx.amount : 0
                      ).toLocaleString()
                    }{" "}
                    Ⱡ
                  </Badge>
                </Col>
              </Row>
              <Row>
                <Col md={3}>Sender:</Col>
                <Col md={3}>
                  <strong>
                    <Link to={`/account/${tx.senderId}`}>
                      {tx.senderUsername ||
                        // TODO: FIX THIS
                        // (tx.sender_tag && tx.sender_tag.tag) ||
                        tx.senderId}
                    </Link>{" "}
                    {tx.senderId && <Copy text={tx.senderId} />}
                  </strong>
                </Col>
                <Col md={3}>Recipient:</Col>
                <Col md={3}>
                  {realRecipient(tx.moduleAssetId)}{" "}
                  {tx.moduleAssetId === "2:0" && (
                    <Copy text={tx.recipientId || ""} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md={3}>Fee:</Col>
                <Col md={3}>
                  <strong>
                    {+beddowsToDecimal(!!tx.fee ? tx.fee : 0).toLocaleString()}{" "}
                    Ⱡ
                  </strong>
                </Col>
                <Col md={3}>Confirmations:</Col>
                <Col md={3}>
                  <strong>{confirmations} confirmations</strong>
                </Col>
              </Row>
              <Row>
                <Col md={3}>TX timestamp:</Col>
                <Col md={3}>
                  <strong>
                    {moment(+tx.timestamp * 1000).format(
                      "dddd D/MM/YYYY - HH:mm:ss Z"
                    )}
                    {" - "}({moment(+tx.blockTimestamp * 1000).fromNow()})
                  </strong>
                </Col>
                <Col md={3}>Block timestamp:</Col>
                <Col md={3}>
                  <strong>
                    {" "}
                    {moment(+tx.blockTimestamp * 1000).format(
                      "dddd D/MM/YYYY - HH:mm:ss Z"
                    )}
                    {" - "}({moment(+tx.blockTimestamp * 1000).fromNow()})
                  </strong>
                </Col>
              </Row>
              <Row style={{ marginTop: 25 }}>
                <TXMetadata tx={tx as TransactionWithBlock} />
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle tag="h4">Present in this block</CardTitle>
        </CardHeader>
        <CardBody
          style={{
            color: document.body.classList.contains("white-content")
              ? "black"
              : "#efefef",
          }}
        >
          <Row>
            <Col md={2} className={"text-center"}>
              <i
                className="tim-icons icon-app"
                style={{ fontSize: "xxx-large" }}
              />
            </Col>
            <Col md={10}>
              <Row>
                <Col md={3}>ID:</Col>
                <Col md={3}>
                  <strong>
                    <Link to={`/block/${tx.blockId}`}>{tx.blockId}</Link>
                  </strong>{" "}
                  <Copy text={tx.blockId} />
                </Col>
                <Col md={3}>Height:</Col>
                <Col md={3}>
                  <strong>{tx.blockHeight}</strong>
                </Col>
              </Row>
              <Row>
                <Col md={3}>timestamp:</Col>
                <Col md={3}>
                  <strong>
                    {moment(+tx.blockTimestamp * 1000 || 0).format(
                      "D/MM/YYYY - HH:mm:ss"
                    )}
                    {" - "}({moment(+tx.blockTimestamp * 1000).fromNow()})
                  </strong>
                </Col>
                <Col md={3}>Validated by:</Col>
                <Col md={3}>
                  {tx.blockUsername ? (
                    <Link to={`/account/${tx.blockAddress}`}>
                      {tx.blockUsername}
                    </Link>
                  ) : (
                    "unknown"
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};
