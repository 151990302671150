import React from "react";
import { ComingSoon } from "../comingSoon/ComingSoon";

export const KnownAddresses: React.FC = () => {
  return <ComingSoon title={"Known Addresses"} />;
};
// import React, { useMemo } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Table,
// } from "reactstrap";
// import { gql } from "apollo-boost";
// import { useQuery } from "@apollo/react-hooks";
// import { IsErrorOrLoading } from "../utils/IsErrorOrLoading";
// import { Stars } from "../../UI/Stars";
// import { KnownAddressElement } from "./KnownAdressElement";
// import {ComingSoon} from "../comingSoon/ComingSoon";
//
// const KNOWN_ADDRESSES_QUERY = gql`
//   query knownAddresses {
//     accounts_tags_public(order_by: { mem_account: { balance: desc } }) {
//       address
//       is_exchange
//       is_liskhq
//       is_scam
//       tag
//       identifier
//       mem_account {
//         balance
//       }
//     }
//   }
// `;
//
// export const KnownAddresses: React.FC = () => {
//   const {
//     data: knownAddressesData,
//     loading: knownAddressesLoading,
//     error: knownAddressesError,
//   } = useQuery(KNOWN_ADDRESSES_QUERY);
//   const knownAddresses = useMemo(() => {
//     if (knownAddressesData?.accounts_tags_public) {
//       return [...knownAddressesData.accounts_tags_public];
//     }
//   }, [knownAddressesData?.accounts_tags_public]);
//
//   if(1 === 1 ) {
//     return (<ComingSoon title={"Known Addresses"}/>)
//   }
//
//   if (knownAddressesLoading || knownAddressesError) {
//     return (
//       <div className="content">
//         <IsErrorOrLoading
//           error={!!knownAddressesError}
//           title={"Known Addresses"}
//         />
//       </div>
//     );
//   }
//
//   return (
//     <div className="content">
//       <Stars />
//       <div className="react-notification-alert-container"></div>
//       <Row>
//         <Col md={6}>
//           <Card className="">
//             <CardHeader>
//               <Row>
//                 <Col xs={10}>
//                   <CardTitle className={"d-inline"}>
//                     <h4 className={""}> Known Exchange Addresses </h4>
//                     <p className="category">
//                       A list of the known exchanges addresses on Lisk
//                     </p>
//                   </CardTitle>
//                 </Col>
//               </Row>
//             </CardHeader>
//             <CardBody>
//               <div className="table-full-width table-responsive">
//                 <Table>
//                   <tbody>
//                     {knownAddresses!
//                       .filter((a: any) => a.is_exchange)
//                       .map((knownAddress: any) => {
//                         return (
//                           <KnownAddressElement
//                             key={knownAddress.address}
//                             address={knownAddress.address}
//                             tag={knownAddress.tag}
//                             balance={knownAddress.mem_account.balance}
//                             isExchange={true}
//                             identifier={knownAddress.identifier}
//                           />
//                         );
//                       })}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//         <Col md={6}>
//           <Card className="">
//             <CardHeader>
//               <Row>
//                 <Col xs={10}>
//                   <CardTitle className={"d-inline"}>
//                     <h4 className={""}> Known LiskHQ Addresses </h4>
//                     <p className="category">
//                       A list of the known LiskHQ addresses
//                     </p>
//                   </CardTitle>
//                 </Col>
//               </Row>
//             </CardHeader>
//             <CardBody>
//               <div className="table-full-width table-responsive">
//                 <Table>
//                   <tbody>
//                     {knownAddresses!
//                       .filter((a: any) => a.is_liskhq)
//                       .map((knownAddress: any) => {
//                         return (
//                           <KnownAddressElement
//                             key={knownAddress.address}
//                             address={knownAddress.address}
//                             tag={knownAddress.tag}
//                             balance={knownAddress.mem_account.balance}
//                             isLiskHQ={true}
//                           />
//                         );
//                       })}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//
//           <Card className="">
//             <CardHeader>
//               <Row>
//                 <Col xs={10}>
//                   <CardTitle className={"d-inline"}>
//                     <h4 className={""}> Known Scam Addresses </h4>
//                     <p className="category">
//                       A list of the known scam addresses on Lisk
//                     </p>
//                   </CardTitle>
//                 </Col>
//               </Row>
//             </CardHeader>
//             <CardBody>
//               <div className="table-full-width table-responsive">
//                 <Table>
//                   <tbody>
//                     {knownAddresses!
//                       .filter((a: any) => a.is_scam)
//                       .map((knownAddress: any) => {
//                         return (
//                           <KnownAddressElement
//                             key={knownAddress.address}
//                             address={knownAddress.address}
//                             tag={knownAddress.tag}
//                             balance={knownAddress.mem_account.balance}
//                             isScam={true}
//                           />
//                         );
//                       })}
//                   </tbody>
//                 </Table>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   );
// };
