import React from "react";
import { NumberCard } from "../../../UI/components/NumberCard";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const LAST_DAY_TX_COUNT_QUERY = gql`
  query txStats {
    txStats {
      lastDay
    }
  }
`;

export const TxLastDayCard: React.FC = () => {
  const { data, loading, error } = useQuery(LAST_DAY_TX_COUNT_QUERY, {
    pollInterval: 60000,
  });

  const totalTxCount = data?.txStats?.lastDay ? data.txStats.lastDay : 0;

  return (
    <>
      <NumberCard
        data={totalTxCount.toLocaleString()}
        icon={"fas fa-angle-double-up"}
        title={"Transactions in the last 24h"}
        loading={loading}
        error={!!error}
      />
    </>
  );
};
