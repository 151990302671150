import React from "react";
import ReactTooltip from "react-tooltip";

const dot = {
  display: 'inline-block',
  marginRight: 10,
  width: "20px",
  height: "20px",
  borderRadius: "26px",
}

const redStatus = {
  backgroundColor: "#FC78A3",
};

const greenStatus = {
  backgroundColor: "#00bf9a",
};

const yellowStatus = {
  backgroundColor: "#e3d05f",
};

const unknownStatus = {
  borderColor: "rgb(191, 115, 0)",
  borderStyle: "dashed",
};

export const delegateStatusColor = (rank: string, isBanned: boolean, missingBlocks: boolean, willForge: boolean) => {
  // const color = +rank > 101 ? {...unknownStatus} : (parseInt(rank) % 2 == 0 ? { ...redStatus } : { ...greenStatus })
  const color = {
    ...dot,
    ...(willForge && greenStatus),
    ...(missingBlocks && yellowStatus),
    ...(isBanned && redStatus),
    ...(!willForge && !missingBlocks && !isBanned && unknownStatus)
  };
  return (
    <>
      <div
        className=""
        style={color}
        data-tip={isBanned? 'Banned' : ''}
      >
        &nbsp;&nbsp;&nbsp;
      </div>
    </>
  );
};
