import React from "react";

export const IDData = ({text}: { text: string }) => (
    <div className="block-card-value-id">
        <i
        className="tim-icons icon-app"
        style={{ fontSize: "xx-large", marginRight: "20px"}}
        />
        {text}
    </div>
);

