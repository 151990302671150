import React, { useMemo, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { IsErrorOrLoading } from "../utils/IsErrorOrLoading";
import { Stars } from "../../UI/Stars";
import { LastMessageElement } from "./LastMessageElement";
import { isBad, sanitizeString } from "../utils/strings/censor";
import { LastMessagesStats } from "./LastMessagesStats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import "./style.css";

const PAYOUT_FILTER_KEYWORDS = [
  "thank",
  "elite",
  "rooney",
  "delegate",
  "support",
];
// const HTTP_FILTER_KEYWORDS = ['http', 'h t t p', 'h*t*t*p', 'delegate', 'support'];

const LAST_MESSAGES_QUERY = gql`
  query eternityWall($page: Int) {
    eternityWall(page: $page) {
      data {
        id
        moduleAssetId
        timestamp
        senderId
        amount
        data
        senderUsername
      }
    }
  }
`;

// onion
// http
// const filter = (msg: string) => {
//   const msgLowerCased = msg.toLowerCase()
//   const trimmed = msgLowerCased.trim();
// const matchDW = [{value: 'o', times: 2}, {value: 'n', times: 2}, {value: 'i', times: 1}]
// const matchHTTP = [{value: 'h', times: 1}, {value: 't', times: 2}, {value: 'p', times: 1}]
//
// const isMatchDW = matchDW.filter((letter) => (msgLowerCased.match(new RegExp(letter.value,"g")) || []).length === letter.times);
// const isMatchHTTP = matchHTTP.filter((letter) => (msgLowerCased.match(new RegExp(letter.value,"g")) || []).length === letter.times);
//
// console.log(isMatchDW.length, matchDW.length, isMatchHTTP.length, matchHTTP.length, msgLowerCased);
// if(isMatchDW.length === matchDW.length || isMatchHTTP.length === matchHTTP.length) {
//   return 'message is unavailable'
// } else {
//   return msg
// }
// }
const sanitize = (tx: any, filterPayouts: boolean) => {
  const sanitized = { ...tx };
  if (isBad(sanitized.transferData)) {
    return null;
  }

  if (
    filterPayouts &&
    PAYOUT_FILTER_KEYWORDS.filter((el) =>
      sanitized?.transferData?.toLowerCase().includes(el)
    ).length
  ) {
    return null;
  }

  // filter exchange message IDs
  if (
    filterPayouts &&
    /[a-z,0-9]{8}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{4}-[a-z,0-9]{12}/.test(
      sanitized.transferData
    )
  ) {
    return null;
  }

  // sanitized.transferData = filter(sanitized.transferData);
  sanitized.transferData = sanitizeString(sanitized.transferData);

  return sanitized;
};

export const LastMessages: React.FC = () => {
  const [page, setPage] = useState(0);
  const [disableFiltersLabel, setDisableFiltersLabel] = useState("Load more");
  const [loadingLabel, setLoadingLabel] = useState("Loading");
  const [filterPayouts, setFilterPayouts] = useState(false);
  const {
    data: lastMessages,
    loading: lastMessagesLoading,
    error: lastMessagesError,
    fetchMore: lastMessagesFetchMore,
  } = useQuery(LAST_MESSAGES_QUERY, {
    variables: {
      page,
    },
  });
  const [isLoading, setIsLoading] = useState(lastMessagesLoading);
  const messages = useMemo(() => {
    if (lastMessages?.eternityWall?.data) {
      setIsLoading(false);
      return [...lastMessages.eternityWall.data]
        .map((tx: any) => sanitize(tx, filterPayouts))
        .filter((tx) => tx !== null);
    }
  }, [lastMessages?.eternityWall?.data, filterPayouts]);

  if (
    ((!lastMessages ||
      !lastMessages.transactions_public ||
      !lastMessages.transactions_public.length) &&
      lastMessagesLoading) ||
    lastMessagesError
  ) {
    return (
      <div className="content">
        <IsErrorOrLoading error={!!lastMessagesError} title={"Eternity wall"} />
      </div>
    );
  }

  const onLoadMore = (depth = 0, depthData: any = []) => {
    setIsLoading(true);
    const actualOffset = !!depthData?.length
      ? depthData.length
      : lastMessages.eternityWall?.data?.length;

    lastMessagesFetchMore({
      variables: {
        page: actualOffset,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }

        const newData = [
          ...(!!depthData.length
            ? [...depthData]
            : (prev as any).eternityWall?.data),
          ...(fetchMoreResult as any).eternityWall?.data,
        ];

        if (
          filterPayouts &&
          newData
            .map((tx: any) => sanitize(tx, filterPayouts))
            .filter((tx) => tx !== null).length === messages!.length &&
          depth <= 5
        ) {
          // @ts-ignore
          setLoadingLabel(
            `Fetching more, because the results where payouts... (Attempt ${
              depth + 1
            } of 6)`
          );
          return onLoadMore(depth + 1, newData);
        }

        setLoadingLabel("Loading");
        if (depth >= 5) {
          setDisableFiltersLabel(
            "Refetched 6 more times, but the results where all payouts. Try again?"
          );
        } else {
          setDisableFiltersLabel("Load more");
        }

        setIsLoading(false);

        return Object.assign({}, prev, {
          eternityWall: {
            data: newData,
          },
        });
      },
    });
  };

  const onFilterPayouts = () => {
    setFilterPayouts(!filterPayouts);
  };

  return (
    <div className="content">
      <Stars />
      <div className="react-notification-alert-container"></div>
      <Row>
        <Col md={9}>
          <Card className="">
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle className={"d-inline"}>
                    <h4 className={""}> Lisk Eternity Wall </h4>
                    <p className="category">
                      Read through the Lisk transactions - This is an
                      experimental feature.
                    </p>
                  </CardTitle>
                </Col>
                <Col>
                  <div
                    className="payout-button "
                    data-tip={
                      filterPayouts
                        ? "Disable payout/exchange IDs filters"
                        : "Enable payout/exchange IDs filters"
                    }
                  >
                    <FontAwesomeIcon
                      id="PayoutFilterTooltip"
                      onClick={onFilterPayouts}
                      title={
                        filterPayouts
                          ? "Disable payout/exchange IDs filters"
                          : "Enable payout/exchange IDs filters"
                      }
                      style={{ fontSize: "20px" }}
                      icon={filterPayouts ? "eye-slash" : "eye"}
                    />
                  </div>
                  <ReactTooltip />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="table-full-width table-responsive">
                <Table>
                  <tbody>
                    {messages!.map((message: any) => {
                      return (
                        <LastMessageElement
                          key={message.id}
                          id={message.id}
                          transferData={message.data}
                          amount={message.amount}
                          timestamp={message.timestamp}
                          sender={message.senderUsername || message.senderId}
                          senderId={message.senderId}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Button
                block
                color="primary"
                onClick={() => onLoadMore()}
                className={isLoading ? "disabled" : ""}
              >
                {isLoading ? (
                  <>
                    <Spinner /> {loadingLabel}
                  </>
                ) : (
                  disableFiltersLabel
                )}
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={3}>
          <LastMessagesStats />
        </Col>
      </Row>
    </div>
  );
};
