import React, { useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ReactTooltip from "react-tooltip";
import { IsErrorOrLoading } from "../../utils/IsErrorOrLoading";
import moment from "moment";
import { Link } from "react-router-dom";
import { useInterval } from "../../customHooks/useInterval";
import { TransactionElement } from "./TransactionElement";
import { BLOCK_HEIGHT_QUERY } from "../../../apollo/queries";
import { TX_TYPES } from "../../utils/const";
import { NoTransactions } from "./NoTransactions";

const LAST_TEN_TX_QUERY = gql`
  query lastTransactionsHome {
    transactions(page: 1) {
      data {
        amount
        senderPublicKey
        recipientId
        id
        moduleAssetId
        timestamp
        senderId
        senderUsername
        recipientId
        recipientUsername
        data
        height
        fee
        #sender_tag {
        #  tag
        #}
        #recipient_tag {
        #  tag
        #}
      }
    }
  }
`;

export const LastTenTransactionsTable: React.FC = () => {
  const { data, loading, error } = useQuery(LAST_TEN_TX_QUERY, {
    pollInterval: 10000,
  });
  const {
    data: blockHeightData,
    loading: heightLoading,
    error: heightError,
  } = useQuery(BLOCK_HEIGHT_QUERY, {
    pollInterval: 5000,
    fetchPolicy: "cache-only",
  });
  const [timestamps, setTimestamps] = useState<{
    [key: string]: { when: number; text: string };
  }>({});
  const [fade, setFade] = useState<boolean>(true);
  const [firstId, setFirstId] = useState<string>("");
  const timestampsLocal: { [key: string]: { when: number; text: string } } = {};
  const detectRecipient = (tx: any) => {
    switch (tx.moduleAssetId) {
      case TX_TYPES.TRANSACTION:
        return (
          tx.recipientUsername ||
          // (tx.recipient_tag && tx.recipient_tag.tag) ||
          tx.recipientId
        );
        break;
      case TX_TYPES.MULTISIG_REG:
        return "Multisig registration";
        break;
      case TX_TYPES.REGISTER_DELEGATE:
        return "Delegate registration";
        break;
      case TX_TYPES.VOTE:
        return "Delegate vote";
        break;
      case TX_TYPES.TOKEN_UNLOCK:
        return "Token unlock";
        break;
      case TX_TYPES.POM_REPORT:
        return "Misbehavior report";
        break;
      case TX_TYPES.LEGACY_ADDRESS_CLAIM:
        return "Legacy address claim";
        break;
      default:
        return "unsupported transaction";
    }
  };
  const blockHeight = blockHeightData?.lastBlock?.height
    ? blockHeightData.lastBlock.height
    : 0;

  useInterval(() => {
    Object.keys(timestampsLocal).forEach((key) => {
      timestampsLocal[key] = {
        when: timestampsLocal[key].when,
        text: moment(timestampsLocal[key].when * 1000).fromNow(),
      };
    });
    setTimestamps(timestampsLocal);
  }, 1000);

  if (error || loading || heightLoading || heightLoading) {
    return (
      <div className="content">
        <IsErrorOrLoading
          error={!!error || !!heightError}
          title={"transactions"}
        />
      </div>
    );
  }

  if (!data.transactions.data.length) {
    return <NoTransactions />;
  }
  if (data.transactions.data[0].id !== firstId) {
    setFade(true);
    setFirstId(data.transactions.data[0].id);
    setTimeout(() => setFade(false), 4000);
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Last Transactions</CardTitle>
      </CardHeader>
      <CardBody>
        <Row
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            maxHeight: "25rem",
            overflowY: "auto",
          }}
        >
          <Col md={12}>
            {data.transactions.data.map((tx: any, index: number) => {
              timestampsLocal[tx.id] = {
                when: tx.timestamp,
                text: moment(tx.timestamp * 1000).fromNow(),
              };

              return (
                <TransactionElement
                  fade={fade}
                  index={index}
                  key={index}
                  id={tx.id}
                  sender={
                    tx.senderUsername ||
                    // (tx.sender_tag && tx.sender_tag.tag) ||
                    tx.senderId ||
                    "unavailable"
                  }
                  senderAddress={tx.senderId}
                  recipient={detectRecipient(tx)}
                  recipientAddress={tx.recipientId || "unavailable"}
                  transferData={tx.data}
                  amount={tx.amount}
                  type={tx.moduleAssetId || "unavailable"}
                  confirmations={blockHeight - tx.height}
                  fee={tx.fee}
                  when={
                    timestamps[tx.id]
                      ? timestamps[tx.id].text
                      : timestampsLocal[tx.id].text
                  }
                />
              );
            })}
          </Col>
        </Row>
        <div className={"text-center"}>
          <Link to={"/transactions"} className={"btn-fill btn btn-secondary"}>
            See more transactions
          </Link>
        </div>
      </CardBody>
      <ReactTooltip />
    </Card>
  );
};
