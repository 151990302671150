import React from "react";

interface Props {
    text: string;
    icon?: string;
}

export const CardData = ({text, icon}: Props) => (
    <div className="block-card-value">
        {icon ? <i
            className={`tim-icons ${icon}`}
            style={{ fontSize: "xx-large", marginRight: "20px"}}
        /> : null}
        {text}
    </div>
);
