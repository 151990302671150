import React from "react";
import {delegateLogo} from "../utils/logos/DelegateLogo";
import {Link} from "react-router-dom";

interface Props {
    username: string;
    address: string
}

const avatarStyleVote = {
    width: "25px",
    height: "25px",
    borderRadius: "25px",
    marginRight: "10px",
};

export const CardUsername = ({username, address}: Props) => (
    <div className="block-card-value">
        {delegateLogo({
        delegateName: username,
        style: avatarStyleVote,
        address: address,
        generateRandom: true,
    })}
        <Link to={`/account/${address}`}>
            {username}
        </Link>
    </div>
);
