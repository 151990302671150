import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import { chartData, chartOptions, color } from "./data";
import { useGetHistoricalPricesQuery } from "../../../generated/graphql";
import { CURRENCY_PAIRS } from "./const";
import { TickerContext } from "../../layouts/BaseLayout";

export const Chart = React.memo(() => {
  const ticker = useContext(TickerContext);
  const { data, error, loading } = useGetHistoricalPricesQuery({
    variables: {
      currency: ticker,
    },
  });
  const options = chartOptions(ticker as CURRENCY_PAIRS);

  const ref = useRef();
  useEffect(() => {
    if (data?.getHistoricalPrices?.value) {
      setTimeout(() => {
        if (ref?.current) {
          // @ts-ignore
          const dataset = ref.current.config.data.datasets[0];
          dataset.backgroundColor = `rgba(${color} ,0.3)`;
          // @ts-ignore
          ref.current.update();
        }
      }, 500);
    }
  }, [data]);

  if (error || loading) {
    return <>Loading</>;
  }

  return data ? (
    <Line
      ref={ref}
      id="line-chart"
      type="line"
      data={chartData(
        // @ts-ignore
        data!.getHistoricalPrices?.date! || [],
        // @ts-ignore
        data!.getHistoricalPrices?.value! || []
      )}
      options={options}
    />
  ) : (
    <></>
  );
});
