import React from "react";
import { Badge, Card, CardBody, CardText, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { AccountInfoQuery, SentVotes } from "../../../generated/graphql";
import { beddowsToDecimal } from "../../utils/lisk/utils/lisk/beddowsToDecimal";
import ReactTooltip from "react-tooltip";
import { delegateLogo } from "../../utils/logos/DelegateLogo";

interface Props {
  votes: SentVotes[];
  setAddressContextReact: (address: string) => void;
}

const avatarStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "25px",
  marginRight: "10px",
};

export const AccountVotes: React.FC<Props> = ({
  votes,
  setAddressContextReact,
}) => {
  return (
    <Card className="card-user">
      <CardBody style={{ minHeight: "unset" }}>
        <CardText />
        <div className="author">
          <h5 className="title">
            {votes.length
              ? `This Account is voting for ${votes.length} delegate${
                  votes.length === 1 ? "" : "s"
                }:`
              : "This account is not voting"}
          </h5>
        </div>
        <div className="card-description">
          <Row>
            <Col md={12}>
              {votes.map((v) => {
                return (
                  <Row>
                    <Col md={12}>
                      <Badge
                        className={"badge-dark"}
                        style={{ padding: 25, margin: 10, borderRadius: 15 }}
                      >
                        <Link to={`/${v.delegateAddress}`}>
                          {delegateLogo({
                            delegateName: v.delegateUsername!,
                            style: avatarStyle,
                            address: v.delegateAddress!,
                            generateRandom: true,
                          })}
                        </Link>
                        <h4 style={{ margin: 0, display: "inline-block" }}>
                          <Link
                            to={`/account/${v.delegateAddress}`}
                            onClick={() =>
                              setAddressContextReact(v.delegateAddress!)
                            }
                          >
                            {v.delegateUsername!}
                          </Link>{" "}
                          ({beddowsToDecimal(v.amount || 0, 2).toLocaleString()}{" "}
                          Ⱡ)
                        </h4>
                      </Badge>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </div>
      </CardBody>
      <ReactTooltip />
    </Card>
  );
};
