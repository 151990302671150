import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
} from "reactstrap";
import { gql } from "apollo-boost";
import { DelegatesElement } from "./DelegateElement";
import { useQuery } from "@apollo/react-hooks";
import { IsErrorOrLoading } from "../utils/IsErrorOrLoading";
import { Stars } from "../../UI/Stars";
import ReactTooltip from "react-tooltip";
import "./style.css";
import { useInterval } from "../customHooks/useInterval";
import moment from "moment";
import { NextForgers } from "./NextForgers";
import { DelegateStats } from "./DelegatesStats";
import { SortableTh } from "./SortableTh";
import { SortingTypes, sortTableColumn, TableColumns } from "./utils/sorting";

export interface Delegates {
  username: string;
}

const DELEGATE_LIST_QUERY = gql`
  query delegatesList {
    delegates {
      address
      username
      dpos {
        delegate {
          username
          pomHeights
          consecutiveMissedBlocks
          producedBlocks
          lastForgedHeight
          isBanned
          totalVotesReceived
          rank
          rankAdjusted
          nextForgingTime
          selfVotesAmount
        }
      }
    }
  }
`;

const LAST_BLOCK_QUERY = gql`
  query lastBlock {
    lastBlock {
      id
      height
    }
  }
`;

export const DelegatesList: React.FC = () => {
  const [sortingType, setSortingType] = useState(SortingTypes.ASC);
  const [sortedColumn, setSortedColumn] = useState(TableColumns.RANK);
  const { data, loading, error } = useQuery(DELEGATE_LIST_QUERY, {
    pollInterval: 5000,
  });
  const {
    data: lastBlock,
    loading: lastBlockLoading,
    error: lastBlockError,
  } = useQuery(LAST_BLOCK_QUERY, {
    pollInterval: 5000,
    fetchPolicy: "cache-only",
  });
  // TODO : Add interface
  const [sortedList, setSortedList] = useState<any[]>([]);
  const [timestamps, setTimestamps] = useState<{
    [key: string]: { when: number; text: string };
  }>({});
  const timestampsLocal: { [key: string]: { when: number; text: string } } = {};
  useInterval(() => {
    Object.keys(timestampsLocal).forEach((key) => {
      timestampsLocal[key] = {
        when: timestampsLocal[key].when,
        text: moment(timestampsLocal[key].when * 1000).fromNow(),
      };
    });
    setTimestamps(timestampsLocal);
  }, 1000);

  useEffect(() => {
    if (data?.delegates) {
      setSortedList(sortTableColumn(data.delegates, sortedColumn, sortingType));
    }
  }, [sortingType, sortedColumn, data]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [sortedList]);

  const setSortingParameters = (column: TableColumns, sort: SortingTypes) => {
    setSortedColumn(column);
    setSortingType(sort);
  };

  if (error || lastBlockError || loading || lastBlockLoading) {
    return (
      <div className="content">
        <IsErrorOrLoading
          error={!!error || !!lastBlockError}
          title={"delegates"}
        />
      </div>
    );
  }
  const delegates: any[] = data.delegates;
  const delegatesSorted = [...delegates]
    .sort(
      (a: any, b: any) =>
        a.dpos.delegate.nextForgingTime - b.dpos.delegate.nextForgingTime
    )
    .filter(
      (delegate: any) =>
        !delegate.dpos.delegate.isBanned &&
        delegate.dpos.delegate.nextForgingTime * 1000 > new Date().getTime()
    );

  return (
    <>
      <Stars />
      <div className="content">
        <div className="react-notification-alert-container"></div>
        <Row>
          <Col md="6">
            <NextForgers delegates={delegatesSorted as any} />
          </Col>
          <Col md="6">
            <DelegateStats totalDelegates={delegates.length} />
          </Col>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Delegates
                  <p className="category">Top delegates by Rank</p>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.RANK}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                      >
                        Rank
                      </SortableTh>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.DELEGATE_NAME}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                      >
                        Delegate
                      </SortableTh>
                      <th></th>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.STATUS}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                        width={"20%"}
                      >
                        Status
                      </SortableTh>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.TOTAL_VOTES}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                      >
                        Total votes
                      </SortableTh>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.PRODUCED_BLOCKS}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                      >
                        Produced Blocks
                      </SortableTh>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.POM}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                      >
                        Misbehaviours
                      </SortableTh>
                      <SortableTh
                        activeHeader={sortedColumn}
                        column={TableColumns.VOTE_CAPACITY}
                        setSorting={setSortingParameters}
                        sortingType={sortingType}
                        className={"text-right"}
                      >
                        Vote Capacity
                      </SortableTh>
                    </tr>
                  </thead>
                  <tbody>
                    <ReactTooltip />
                    {sortedList.map((delegate: any) => {
                      const forgingTime =
                        delegate.dpos.delegate.nextForgingTime;
                      timestampsLocal[delegate.username] = {
                        when: forgingTime,
                        text: moment(forgingTime * 1000).fromNow(),
                      };

                      return (
                        <DelegatesElement
                          rank={delegate.dpos.delegate.rank}
                          rankAdjusted={delegate.dpos.delegate.rankAdjusted}
                          address={delegate.address}
                          missedBlocks={
                            delegate.dpos.delegate.consecutiveMissedBlocks
                          }
                          producedBlocks={delegate.dpos.delegate.producedBlocks}
                          rewards={0 || "delegate.rewards"}
                          PoM={delegate.dpos.pomHeights || []}
                          vote={delegate.dpos.delegate.totalVotesReceived}
                          username={delegate.username}
                          affiliation={""}
                          isBanned={delegate.dpos.delegate.isBanned}
                          nextForgingTime={
                            timestamps[delegate.username]
                              ? timestamps[delegate.username].text
                              : timestampsLocal[delegate.username].text
                          }
                          selfVote={delegate.dpos.delegate.selfVotesAmount}
                          willForge={forgingTime > 0}
                          key={delegate.address}
                        />
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};
