import React from "react";
import { Row, Col } from "reactstrap";
import { SearchCard } from "../../UI/components/SearchCard";
import { HeaderCard } from "../../UI/components/HeaderCard";
import { BlockHeightCard } from "./blockHeightCard/BlockHeightCard";
import { TotalSupplyCard } from "./totalSupplyCard/TotalSupplyCard";
import { TxLastDayCard } from "./txLastDayCard/TxLastDayCard";
import { LastTenTransactionsTable } from "./lastTenTransactionsTable/LastTenTransactionsTable";
import { LastTenBlocksTable } from "./lastTenBlocks/LastTenBlocks";
import { Stars } from "../../UI/Stars";

export const BlockchainOverview: React.FC = () => {
  return (
    <>
      <Stars />
      <div className="content">
        <Row>
          <Col xs="12">
            <HeaderCard />
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <SearchCard />
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <BlockHeightCard />
          </Col>
          <Col lg="4">
            <TotalSupplyCard />
          </Col>
          <Col lg="4">
            <TxLastDayCard />
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="12">
            <LastTenTransactionsTable />
          </Col>
          <Col lg="6" md="12">
            <LastTenBlocksTable />
          </Col>
        </Row>
      </div>
    </>
  );
};
