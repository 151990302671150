import unknown from "./delegateLogos/unknown.jpg";
import React from "react";

interface DelegateLogosDictionary {
  [key: string]: {
    img: string;
  };
}
interface DelegateLogo {
  delegateName: string;
  className?: string;
  address?: string;
  generateRandom?: boolean;
  style?: any;
}
const delegateLogosDictionary: DelegateLogosDictionary = {
  carbonara: {
    img: require("./delegateLogos/carbonara.jpg"),
  },
  tonyt908: {
    img: require("./delegateLogos/tonyt908.jpg"),
  },
  liskmagazine: {
    img: require("./delegateLogos/liskmagazine.jpg"),
  },
  przemer: {
    img: require("./delegateLogos/przemer.jpg"),
  },
  chamberlain: {
    img: require("./delegateLogos/chamberlain.jpg"),
  },
  jong: {
    img: require("./delegateLogos/jong.jpg"),
  },
  gym: {
    img: require("./delegateLogos/gym.png"),
  },
  moosty: {
    img: require("./delegateLogos/moosty.jpg"),
  },
  stellardynamic: {
    img: require("./delegateLogos/stellardynamic.jpg"),
  },
  alepop: {
    img: require("./delegateLogos/alepop.jpg"),
  },
  cc001: {
    img: require("./delegateLogos/cc001.jpg"),
  },
  endro: {
    img: require("./delegateLogos/endro.jpg"),
  },
  forger_of_lisk: {
    img: require("./delegateLogos/forger_of_lisk.jpg"),
  },
  korben3: {
    img: require("./delegateLogos/korben3.jpg"),
  },
  lemii: {
    img: require("./delegateLogos/lemii.jpg"),
  },
  "liskpoland.pl": {
    img: require("./delegateLogos/liskpolandpl.jpg"),
  },
  minions: {
    img: require("./delegateLogos/minions.jpg"),
  },
  mrv: {
    img: require("./delegateLogos/mrv.jpg"),
  },
  samuray: {
    img: require("./delegateLogos/samuray.jpg"),
  },
  seven: {
    img: require("./delegateLogos/seven.jpg"),
  },
  spacetrucker: {
    img: require("./delegateLogos/spacetrucker.jpg"),
  },
  splatters: {
    img: require("./delegateLogos/splatters.jpg"),
  },
  thamar: {
    img: require("./delegateLogos/thamar.jpg"),
  },
};

export const delegateLogo = ({
  delegateName,
  className,
  address,
  style,
  generateRandom = false,
}: DelegateLogo) => {
  const image = delegateLogosDictionary[delegateName]
    ? delegateLogosDictionary[delegateName].img
    : generateRandom && address
    ? `https://avatars.lisk.observer/${address}`
    : unknown;
  return <img src={image} style={style} alt="avatar" className={className} />;
};
