import {
  Alert,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row
} from "reactstrap";
import { Link } from "react-router-dom";
import React from "react";

interface Props {
  totalDelegates: number;
}

export const DelegateStats: React.FC<Props> = ({ totalDelegates }) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Consensus stats:</CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <span data-notify="message">
              <span
                className="tim-icons icon-paper left-icon"
                data-notify="icon"
              />{" "}
              <span className="inline-element">
                <strong className="important-value-label">Blocktime:</strong>
                <div className="left20"><p>~ 10 seconds</p></div>
              </span>
            </span>
          </Col>
          <Col>
            <span data-notify="message">
              <span
                className="tim-icons icon-check-2 left-icon"
                data-notify="icon"
              />{" "}
              <span className="inline-element">
                <strong className="important-value-label">
                  Active delegates:
                </strong>
                <div className="left20"><p>103</p></div>
              </span>
            </span>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <span
              className="tim-icons icon-time-alarm left-icon"
              data-notify="icon"
            />{" "}
            <span className="inline-element">
              <strong className="important-value-label">
                Standby delegates:
              </strong>{" "}
              <div className="left20"><p>{totalDelegates - 103}</p></div>
            </span>
            <br />
          </Col>
          <Col>
            <span data-notify="message">
              <span
                className="tim-icons icon-link-72 left-icon"
                data-notify="icon"
              />{" "}
              <span className="inline-element">
                <strong className="important-value-label">
                  Total delegates:
                </strong>{" "}
                <div className="left20"><p>{totalDelegates}</p></div>
              </span>
            </span>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
