import React from "react";
import { ComingSoon } from "../comingSoon/ComingSoon";

export const LastMessagesStats: React.FC = () => {
  return <ComingSoon title={"Stats"} />;
};
// import React, { useState } from "react";
// import {
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Row,
//   Col,
//   Badge,
// } from "reactstrap";
// import { IsErrorOrLoading } from "../utils/IsErrorOrLoading";
// import { useQuery } from "@apollo/react-hooks";
// import { toLiskEpoch } from "../utils/lisk/utils/lisk/time";
// import { gql } from "apollo-boost";
// import { beddowsToDecimal } from "../utils/lisk/utils/lisk/beddowsToDecimal";
//
// // TODO: Re-enable with apollo-client 3.0
// // const STATS_QUERY = gql`
// //     fragment stat on transactions_public_aggregate {
// //         aggregate {
// //             sum {
// //                 fee
// //             }
// //             count(columns: id)
// //             avg {
// //                 amount
// //             }
// //         }
// //     }
// //
// //     query messagesStats($lastDay: Int!) {
// //         lastDay: transactions_public_aggregate(where: {_and: [{type: {_eq: "0"}}, {transferData: {_is_null: false}}, {timestamp: {_gt: $lastDay}}]}) {
// //             ...stat
// //         }
// //         #allTime: transactions_public_aggregate(where: {_and: [{type: {_eq: "0"}}, {transferData: {_is_null: false}}]}) {
// //         #    ...stat
// //         #}
// //     }
// // `;
//
// const ALL_TIME_STATS_QUERY = gql`
//   query allTime {
//     transactions_public_aggregate(
//       where: {
//         _and: [{ type: { _eq: "0" } }, { transferData: { _is_null: false } }]
//       }
//     ) {
//       aggregate {
//         sum {
//           fee
//         }
//         count(columns: id)
//         avg {
//           amount
//         }
//       }
//     }
//   }
// `;
// const LAST_DAY_STATS_QUERY = gql`
//   query lastDay($yesterday: Int!) {
//     transactions_public_aggregate(
//       where: {
//         _and: [
//           { type: { _eq: "0" } }
//           { transferData: { _is_null: false } }
//           { timestamp: { _gt: $yesterday } }
//         ]
//       }
//     ) {
//       aggregate {
//         sum {
//           fee
//         }
//         count(columns: id)
//         avg {
//           amount
//         }
//       }
//     }
//   }
// `;
//
// export const LastMessagesStats: React.FC = () => {
//   const yesterday = toLiskEpoch(
//     new Date(new Date().getTime() - 24 * 60 * 60 * 1000).getTime()
//   );
//   const [yesterdayHook, setYesterdayHook] = useState(0);
//   if (yesterdayHook === 0) {
//     setYesterdayHook(yesterday);
//   }
//   const {
//     data: allTimeMessagesStats,
//     loading: allTimeMessagesStatsLoading,
//     error: allTimeMessagesStatsError,
//   } = useQuery(ALL_TIME_STATS_QUERY);
//   const {
//     data: lastDaymessagesStats,
//     loading: lastDaymessagesStatsLoading,
//     error: lastDaymessagesStatsError,
//   } = useQuery(LAST_DAY_STATS_QUERY, {
//     variables: {
//       yesterday: yesterdayHook,
//     },
//   });
//
//   if (
//     allTimeMessagesStatsLoading ||
//     allTimeMessagesStatsError ||
//     lastDaymessagesStatsLoading ||
//     lastDaymessagesStatsError ||
//       1 === 1
//   ) {
//     return (
//       <div className="content">
//         <IsErrorOrLoading
//           // error={!!allTimeMessagesStatsError || !!lastDaymessagesStatsError}
//           error={true}
//           errorMsg={"Coming soon"}
//           title={"last messages stats"}
//         />
//       </div>
//     );
//   }
//
//   const allTime = allTimeMessagesStats.transactions_public_aggregate.aggregate;
//   const lastDay = lastDaymessagesStats.transactions_public_aggregate.aggregate;
//
//   return (
//     <Card
//       className=""
//       style={{
//         color: document.body.classList.contains("white-content")
//           ? "black"
//           : "#efefef",
//       }}
//     >
//       <CardHeader>
//         <CardTitle className={"d-inline"}>
//           <h4 className={""}> Lisk Eternity Wall - Stats </h4>
//         </CardTitle>
//       </CardHeader>
//       <CardBody>
//         <Row>
//           <Col xs={12} className={"text-center"}>
//             <h4>Last day:</h4>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>Total messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             {lastDay.count}
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>Fee messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
//               {beddowsToDecimal(lastDay.sum.fee, 2).toLocaleString()} Ⱡ
//             </Badge>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>AVG LSK in messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
//               {beddowsToDecimal(lastDay.avg.amount, 2).toLocaleString()} Ⱡ
//             </Badge>
//           </Col>
//         </Row>
//
//         <div
//           style={{
//             marginTop: "10px",
//             marginBottom: "10px",
//             borderTop: "1px solid rgba(255,255,255,.2)",
//           }}
//         ></div>
//
//         <Row>
//           <Col xs={12} className={"text-center"}>
//             <h4>All time:</h4>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>Total messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             {allTime.count}
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>Fee messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
//               {beddowsToDecimal(allTime.sum.fee, 2).toLocaleString()} Ⱡ
//             </Badge>
//           </Col>
//         </Row>
//         <Row>
//           <Col md={6}>
//             <strong>AVG LSK in messages:</strong>
//           </Col>
//           <Col md={6} className="text-right">
//             <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
//               {beddowsToDecimal(allTime.avg.amount, 2).toLocaleString()} Ⱡ
//             </Badge>
//           </Col>
//         </Row>
//       </CardBody>
//     </Card>
//   );
// };
