import React from "react";
import { Container, Nav, NavItem } from "reactstrap";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

const NODE_INFO_QUERY = gql`
  query nodeInfo {
    nodeInfo {
      name
    }
  }
`;
export const Footer: React.FC = () => {
  const { data } = useQuery(NODE_INFO_QUERY);
  return (
    <footer className="footer">
      <Container fluid>
        <Nav>
          <NavItem>
            {process.env.REACT_APP_NETWORK} | {data?.nodeInfo?.name || ""} |
            {process.env.REACT_APP_VERSION}
          </NavItem>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} - Observing the Lisk blockchain since
          2019.
        </div>
      </Container>
    </footer>
  );
};
