import { library } from "@fortawesome/fontawesome-svg-core";
import { faComment as farComment } from "@fortawesome/free-regular-svg-icons";
import {
  faCube,
  faExchangeAlt,
  faServer,
  faUser,
  faBinoculars,
  faComment,
  faCog,
  faCaretUp,
  faCaretDown,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faFingerprint,
  faCopy,
  faSlidersH,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCube,
  faExchangeAlt,
  faServer,
  faUser,
  faBinoculars,
  faComment,
  farComment,
  faCog,
  faCaretUp,
  faCaretDown,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faFingerprint,
  faCopy,
  faSlidersH,
  faAngleDown
);
