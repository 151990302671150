import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Account = {
  __typename?: 'Account';
  username?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  isDelegate?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Token>;
  sequence?: Maybe<Sequence>;
  keys?: Maybe<Keys>;
  dpos?: Maybe<Dpos>;
  hexAddress?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
};

export type AccountDelegate = {
  __typename?: 'AccountDelegate';
  address?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  dpos?: Maybe<Dpos>;
};

export type AccountSearch = {
  __typename?: 'AccountSearch';
  username?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
};

export type Block = {
  __typename?: 'Block';
  id: Scalars['String'];
  height: Scalars['String'];
  timestamp: Scalars['String'];
  generatorPublicKey: Scalars['String'];
  reward: Scalars['String'];
  isFinal: Scalars['String'];
  username: Scalars['String'];
  address: Scalars['String'];
};

export type BlockSearch = {
  __typename?: 'BlockSearch';
  id?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
};

export type BlockWithTransactions = {
  __typename?: 'BlockWithTransactions';
  block?: Maybe<Block>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
};

export enum Currencies {
  Lskusd = 'LSKUSD',
  Lskbtc = 'LSKBTC',
  Lskeur = 'LSKEUR',
  Lskkrw = 'LSKKRW',
  Lskpln = 'LSKPLN',
  Lskjpy = 'LSKJPY',
  Lskcny = 'LSKCNY',
  Lskaed = 'LSKAED'
}

export type CurrencyData = {
  __typename?: 'CurrencyData';
  currency?: Maybe<Currencies>;
  date?: Maybe<Array<Maybe<Scalars['String']>>>;
  value?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Delegate = {
  __typename?: 'Delegate';
  username?: Maybe<Scalars['String']>;
  pomHeights?: Maybe<Array<Maybe<Scalars['String']>>>;
  consecutiveMissedBlocks?: Maybe<Scalars['String']>;
  lastForgedHeight?: Maybe<Scalars['String']>;
  isBanned?: Maybe<Scalars['Boolean']>;
  totalVotesReceived?: Maybe<Scalars['String']>;
  producedBlocks?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['String']>;
  rankAdjusted?: Maybe<Scalars['String']>;
  minActiveHeight?: Maybe<Scalars['Int']>;
  isConsensusParticipant?: Maybe<Scalars['Boolean']>;
  nextForgingTime?: Maybe<Scalars['Int']>;
  selfVotesAmount?: Maybe<Scalars['Float']>;
};

export type Dpos = {
  __typename?: 'Dpos';
  delegate?: Maybe<Delegate>;
  sentVotes?: Maybe<Array<Maybe<SentVotes>>>;
  unlocking?: Maybe<Array<Maybe<Unlocking>>>;
};

export type EthernityWallMessage = {
  __typename?: 'EthernityWallMessage';
  id: Scalars['String'];
  moduleAssetId: Scalars['String'];
  timestamp: Scalars['String'];
  senderId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  senderUsername?: Maybe<Scalars['String']>;
};

export type Keys = {
  __typename?: 'Keys';
  numberOfSignatures?: Maybe<Scalars['String']>;
  mandatoryKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  optionalKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LastTicks = {
  __typename?: 'LastTicks';
  LSKUSD?: Maybe<Scalars['Float']>;
  LSKBTC?: Maybe<Scalars['Float']>;
  LSKEUR?: Maybe<Scalars['Float']>;
  LSKKRW?: Maybe<Scalars['Float']>;
  LSKPLN?: Maybe<Scalars['Float']>;
  LSKJPY?: Maybe<Scalars['Float']>;
  LSKCNY?: Maybe<Scalars['Float']>;
  LSKAED?: Maybe<Scalars['Float']>;
};

export type MultisigRegistration = {
  __typename?: 'MultisigRegistration';
  numberOfSignatures?: Maybe<Scalars['Int']>;
  mandatoryKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  optionalKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NodeInfo = {
  __typename?: 'NodeInfo';
  name?: Maybe<Scalars['String']>;
};

export type PaginatedBlock = {
  __typename?: 'PaginatedBlock';
  data?: Maybe<Array<Maybe<Block>>>;
  pagination?: Maybe<Pagination>;
};

export type PaginatedEthernityWallMessage = {
  __typename?: 'PaginatedEthernityWallMessage';
  data?: Maybe<Array<Maybe<EthernityWallMessage>>>;
  pagination?: Maybe<Pagination>;
};

export type PaginatedTransaction = {
  __typename?: 'PaginatedTransaction';
  data?: Maybe<Array<Maybe<Transaction>>>;
  pagination?: Maybe<Pagination>;
};

export type Pagination = {
  __typename?: 'Pagination';
  total?: Maybe<Scalars['Int']>;
  lastPage?: Maybe<Scalars['Int']>;
  currentPage?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['Int']>;
  to?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  block?: Maybe<BlockWithTransactions>;
  lastBlock?: Maybe<Block>;
  lastBlocks?: Maybe<PaginatedBlock>;
  account?: Maybe<Account>;
  transactionsByAddress?: Maybe<PaginatedTransaction>;
  transaction?: Maybe<TransactionWithBlock>;
  transactions?: Maybe<PaginatedTransaction>;
  eternityWall?: Maybe<PaginatedEthernityWallMessage>;
  txStats?: Maybe<TxStats>;
  delegates?: Maybe<Array<Maybe<AccountDelegate>>>;
  nodeInfo?: Maybe<NodeInfo>;
  search?: Maybe<Search>;
  getHistoricalPrices?: Maybe<CurrencyData>;
  lastTicks?: Maybe<LastTicks>;
};


export type QueryBlockArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryLastBlocksArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QueryAccountArgs = {
  address: Scalars['String'];
};


export type QueryTransactionsByAddressArgs = {
  address: Scalars['String'];
  page: Scalars['Int'];
};


export type QueryTransactionArgs = {
  id: Scalars['String'];
};


export type QueryTransactionsArgs = {
  page?: Maybe<Scalars['Int']>;
  TXType?: Maybe<Scalars['String']>;
};


export type QueryEternityWallArgs = {
  page?: Maybe<Scalars['Int']>;
};


export type QuerySearchArgs = {
  term: Scalars['String'];
};


export type QueryGetHistoricalPricesArgs = {
  currency?: Maybe<Scalars['String']>;
};

export type Search = {
  __typename?: 'Search';
  accounts?: Maybe<Array<Maybe<AccountSearch>>>;
  transactions?: Maybe<Array<Maybe<TransactionSearch>>>;
  blocks?: Maybe<Array<Maybe<BlockSearch>>>;
};

export type SentVotes = {
  __typename?: 'SentVotes';
  delegateAddress?: Maybe<Scalars['String']>;
  delegateUsername?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
};

export type Sequence = {
  __typename?: 'Sequence';
  nonce?: Maybe<Scalars['String']>;
};

export type TxStats = {
  __typename?: 'TXStats';
  lastDay?: Maybe<Scalars['Int']>;
};

export type Token = {
  __typename?: 'Token';
  balance?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['String']>;
};

export type TokenUnlock = {
  __typename?: 'TokenUnlock';
  delegateAddress?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  unvoteHeight?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['String'];
  height: Scalars['String'];
  moduleAssetId: Scalars['String'];
  nonce: Scalars['String'];
  blockId: Scalars['String'];
  timestamp: Scalars['String'];
  senderPublicKey?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  minFee?: Maybe<Scalars['String']>;
  senderUsername?: Maybe<Scalars['String']>;
  recipientUsername?: Maybe<Scalars['String']>;
  votes?: Maybe<Array<Maybe<Vote>>>;
};

export type TransactionSearch = {
  __typename?: 'TransactionSearch';
  id?: Maybe<Scalars['String']>;
};

export type TransactionWithBlock = {
  __typename?: 'TransactionWithBlock';
  id: Scalars['String'];
  height: Scalars['String'];
  moduleAssetId: Scalars['String'];
  nonce: Scalars['String'];
  blockId: Scalars['String'];
  timestamp: Scalars['String'];
  senderPublicKey?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['String']>;
  recipientId?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  senderUsername?: Maybe<Scalars['String']>;
  recipientUsername?: Maybe<Scalars['String']>;
  blockHeight?: Maybe<Scalars['String']>;
  blockTimestamp: Scalars['String'];
  blockGeneratorPublicKey: Scalars['String'];
  blockIsFinal: Scalars['String'];
  blockUsername: Scalars['String'];
  blockAddress?: Maybe<Scalars['String']>;
  votes?: Maybe<Array<Maybe<Vote>>>;
  multisigRegistration?: Maybe<MultisigRegistration>;
  tokenUnlock?: Maybe<Array<Maybe<TokenUnlock>>>;
};

export type Unlocking = {
  __typename?: 'Unlocking';
  delegateAddress?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  unvoteHeight?: Maybe<Scalars['String']>;
};

export type Vote = {
  __typename?: 'Vote';
  delegateAddress?: Maybe<Scalars['String']>;
  delegateUsername?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
};

export type AccountInfoQueryVariables = Exact<{
  addressContext: Scalars['String'];
}>;


export type AccountInfoQuery = (
  { __typename?: 'Query' }
  & { account?: Maybe<(
    { __typename?: 'Account' }
    & Pick<Account, 'username' | 'address' | 'isDelegate' | 'hexAddress' | 'publicKey'>
    & { token?: Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'balance' | 'locked'>
    )>, sequence?: Maybe<(
      { __typename?: 'Sequence' }
      & Pick<Sequence, 'nonce'>
    )>, keys?: Maybe<(
      { __typename?: 'Keys' }
      & Pick<Keys, 'optionalKeys' | 'mandatoryKeys' | 'numberOfSignatures'>
    )>, dpos?: Maybe<(
      { __typename?: 'Dpos' }
      & { delegate?: Maybe<(
        { __typename?: 'Delegate' }
        & Pick<Delegate, 'username' | 'pomHeights' | 'consecutiveMissedBlocks' | 'lastForgedHeight' | 'isBanned' | 'totalVotesReceived'>
      )>, sentVotes?: Maybe<Array<Maybe<(
        { __typename?: 'SentVotes' }
        & Pick<SentVotes, 'delegateAddress' | 'delegateUsername' | 'amount'>
      )>>>, unlocking?: Maybe<Array<Maybe<(
        { __typename?: 'Unlocking' }
        & Pick<Unlocking, 'delegateAddress' | 'amount' | 'unvoteHeight'>
      )>>> }
    )> }
  )> }
);

export type BlockHeightQueryVariables = Exact<{ [key: string]: never; }>;


export type BlockHeightQuery = (
  { __typename?: 'Query' }
  & { lastBlock?: Maybe<(
    { __typename?: 'Block' }
    & Pick<Block, 'id' | 'height'>
  )> }
);

export type GetHistoricalPricesQueryVariables = Exact<{
  currency: Scalars['String'];
}>;


export type GetHistoricalPricesQuery = (
  { __typename?: 'Query' }
  & { getHistoricalPrices?: Maybe<(
    { __typename?: 'CurrencyData' }
    & Pick<CurrencyData, 'currency' | 'value' | 'date'>
  )> }
);

export type LastTicksQueryVariables = Exact<{ [key: string]: never; }>;


export type LastTicksQuery = (
  { __typename?: 'Query' }
  & { lastTicks?: Maybe<(
    { __typename?: 'LastTicks' }
    & Pick<LastTicks, 'LSKUSD' | 'LSKBTC' | 'LSKEUR' | 'LSKKRW' | 'LSKPLN' | 'LSKJPY' | 'LSKCNY' | 'LSKAED'>
  )> }
);

export type PaginatedBlocksQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
}>;


export type PaginatedBlocksQuery = (
  { __typename?: 'Query' }
  & { lastBlocks?: Maybe<(
    { __typename?: 'PaginatedBlock' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Block' }
      & Pick<Block, 'id' | 'height' | 'username' | 'generatorPublicKey'>
    )>>>, pagination?: Maybe<(
      { __typename?: 'Pagination' }
      & Pick<Pagination, 'total' | 'currentPage' | 'from' | 'to'>
    )> }
  )> }
);

export type PaginatedTransactionsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  TXType?: Maybe<Scalars['String']>;
}>;


export type PaginatedTransactionsQuery = (
  { __typename?: 'Query' }
  & { transactions?: Maybe<(
    { __typename?: 'PaginatedTransaction' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'amount' | 'senderPublicKey' | 'senderId' | 'recipientId' | 'id' | 'moduleAssetId' | 'timestamp' | 'senderUsername' | 'recipientUsername' | 'data' | 'height' | 'fee'>
    )>>>, pagination?: Maybe<(
      { __typename?: 'Pagination' }
      & Pick<Pagination, 'total' | 'currentPage' | 'from' | 'to'>
    )> }
  )> }
);

export type PaginatedTransactionsByAddressQueryVariables = Exact<{
  addressContext: Scalars['String'];
  page: Scalars['Int'];
}>;


export type PaginatedTransactionsByAddressQuery = (
  { __typename?: 'Query' }
  & { transactionsByAddress?: Maybe<(
    { __typename?: 'PaginatedTransaction' }
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'height' | 'moduleAssetId' | 'nonce' | 'blockId' | 'timestamp' | 'senderPublicKey' | 'senderId' | 'recipientId' | 'amount' | 'data' | 'size' | 'fee' | 'minFee' | 'senderUsername' | 'recipientUsername'>
    )>>>, pagination?: Maybe<(
      { __typename?: 'Pagination' }
      & Pick<Pagination, 'total' | 'currentPage' | 'from' | 'to'>
    )> }
  )> }
);

export type SearchboxQueryVariables = Exact<{
  term: Scalars['String'];
}>;


export type SearchboxQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<(
    { __typename?: 'Search' }
    & { transactions?: Maybe<Array<Maybe<(
      { __typename?: 'TransactionSearch' }
      & Pick<TransactionSearch, 'id'>
    )>>>, blocks?: Maybe<Array<Maybe<(
      { __typename?: 'BlockSearch' }
      & Pick<BlockSearch, 'height' | 'id'>
    )>>>, accounts?: Maybe<Array<Maybe<(
      { __typename?: 'AccountSearch' }
      & Pick<AccountSearch, 'username' | 'address'>
    )>>> }
  )> }
);

export type TransctionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TransctionByIdQuery = (
  { __typename?: 'Query' }
  & { transaction?: Maybe<(
    { __typename?: 'TransactionWithBlock' }
    & Pick<TransactionWithBlock, 'amount' | 'senderPublicKey' | 'senderId' | 'recipientId' | 'id' | 'timestamp' | 'moduleAssetId' | 'senderUsername' | 'recipientUsername' | 'data' | 'height' | 'fee' | 'blockId' | 'blockHeight' | 'blockTimestamp' | 'blockGeneratorPublicKey' | 'blockIsFinal' | 'blockUsername' | 'blockAddress'>
    & { votes?: Maybe<Array<Maybe<(
      { __typename?: 'Vote' }
      & Pick<Vote, 'delegateAddress' | 'delegateUsername' | 'amount'>
    )>>>, multisigRegistration?: Maybe<(
      { __typename?: 'MultisigRegistration' }
      & Pick<MultisigRegistration, 'numberOfSignatures' | 'mandatoryKeys' | 'optionalKeys'>
    )>, tokenUnlock?: Maybe<Array<Maybe<(
      { __typename?: 'TokenUnlock' }
      & Pick<TokenUnlock, 'delegateAddress' | 'amount' | 'unvoteHeight' | 'username'>
    )>>> }
  )> }
);


export const AccountInfoDocument = gql`
    query accountInfo($addressContext: String!) {
  account(address: $addressContext) {
    username
    address
    isDelegate
    token {
      balance
      locked
    }
    sequence {
      nonce
    }
    keys {
      optionalKeys
      mandatoryKeys
      numberOfSignatures
    }
    dpos {
      delegate {
        username
        pomHeights
        consecutiveMissedBlocks
        lastForgedHeight
        isBanned
        totalVotesReceived
      }
      sentVotes {
        delegateAddress
        delegateUsername
        amount
      }
      unlocking {
        delegateAddress
        amount
        unvoteHeight
      }
    }
    hexAddress
    publicKey
  }
}
    `;

/**
 * __useAccountInfoQuery__
 *
 * To run a query within a React component, call `useAccountInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountInfoQuery({
 *   variables: {
 *      addressContext: // value for 'addressContext'
 *   },
 * });
 */
export function useAccountInfoQuery(baseOptions: Apollo.QueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
      }
export function useAccountInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountInfoQuery, AccountInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountInfoQuery, AccountInfoQueryVariables>(AccountInfoDocument, options);
        }
export type AccountInfoQueryHookResult = ReturnType<typeof useAccountInfoQuery>;
export type AccountInfoLazyQueryHookResult = ReturnType<typeof useAccountInfoLazyQuery>;
export type AccountInfoQueryResult = Apollo.QueryResult<AccountInfoQuery, AccountInfoQueryVariables>;
export const BlockHeightDocument = gql`
    query blockHeight {
  lastBlock {
    id
    height
  }
}
    `;

/**
 * __useBlockHeightQuery__
 *
 * To run a query within a React component, call `useBlockHeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockHeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockHeightQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlockHeightQuery(baseOptions?: Apollo.QueryHookOptions<BlockHeightQuery, BlockHeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockHeightQuery, BlockHeightQueryVariables>(BlockHeightDocument, options);
      }
export function useBlockHeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockHeightQuery, BlockHeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockHeightQuery, BlockHeightQueryVariables>(BlockHeightDocument, options);
        }
export type BlockHeightQueryHookResult = ReturnType<typeof useBlockHeightQuery>;
export type BlockHeightLazyQueryHookResult = ReturnType<typeof useBlockHeightLazyQuery>;
export type BlockHeightQueryResult = Apollo.QueryResult<BlockHeightQuery, BlockHeightQueryVariables>;
export const GetHistoricalPricesDocument = gql`
    query getHistoricalPrices($currency: String!) {
  getHistoricalPrices(currency: $currency) {
    currency
    value
    date
  }
}
    `;

/**
 * __useGetHistoricalPricesQuery__
 *
 * To run a query within a React component, call `useGetHistoricalPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHistoricalPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHistoricalPricesQuery({
 *   variables: {
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useGetHistoricalPricesQuery(baseOptions: Apollo.QueryHookOptions<GetHistoricalPricesQuery, GetHistoricalPricesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHistoricalPricesQuery, GetHistoricalPricesQueryVariables>(GetHistoricalPricesDocument, options);
      }
export function useGetHistoricalPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHistoricalPricesQuery, GetHistoricalPricesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHistoricalPricesQuery, GetHistoricalPricesQueryVariables>(GetHistoricalPricesDocument, options);
        }
export type GetHistoricalPricesQueryHookResult = ReturnType<typeof useGetHistoricalPricesQuery>;
export type GetHistoricalPricesLazyQueryHookResult = ReturnType<typeof useGetHistoricalPricesLazyQuery>;
export type GetHistoricalPricesQueryResult = Apollo.QueryResult<GetHistoricalPricesQuery, GetHistoricalPricesQueryVariables>;
export const LastTicksDocument = gql`
    query lastTicks {
  lastTicks {
    LSKUSD
    LSKBTC
    LSKEUR
    LSKKRW
    LSKPLN
    LSKJPY
    LSKCNY
    LSKAED
  }
}
    `;

/**
 * __useLastTicksQuery__
 *
 * To run a query within a React component, call `useLastTicksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastTicksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastTicksQuery({
 *   variables: {
 *   },
 * });
 */
export function useLastTicksQuery(baseOptions?: Apollo.QueryHookOptions<LastTicksQuery, LastTicksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastTicksQuery, LastTicksQueryVariables>(LastTicksDocument, options);
      }
export function useLastTicksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastTicksQuery, LastTicksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastTicksQuery, LastTicksQueryVariables>(LastTicksDocument, options);
        }
export type LastTicksQueryHookResult = ReturnType<typeof useLastTicksQuery>;
export type LastTicksLazyQueryHookResult = ReturnType<typeof useLastTicksLazyQuery>;
export type LastTicksQueryResult = Apollo.QueryResult<LastTicksQuery, LastTicksQueryVariables>;
export const PaginatedBlocksDocument = gql`
    query paginatedBlocks($page: Int) {
  lastBlocks(page: $page) {
    data {
      id
      height
      username
      generatorPublicKey
    }
    pagination {
      total
      currentPage
      from
      to
    }
  }
}
    `;

/**
 * __usePaginatedBlocksQuery__
 *
 * To run a query within a React component, call `usePaginatedBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedBlocksQuery({
 *   variables: {
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedBlocksQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedBlocksQuery, PaginatedBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedBlocksQuery, PaginatedBlocksQueryVariables>(PaginatedBlocksDocument, options);
      }
export function usePaginatedBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedBlocksQuery, PaginatedBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedBlocksQuery, PaginatedBlocksQueryVariables>(PaginatedBlocksDocument, options);
        }
export type PaginatedBlocksQueryHookResult = ReturnType<typeof usePaginatedBlocksQuery>;
export type PaginatedBlocksLazyQueryHookResult = ReturnType<typeof usePaginatedBlocksLazyQuery>;
export type PaginatedBlocksQueryResult = Apollo.QueryResult<PaginatedBlocksQuery, PaginatedBlocksQueryVariables>;
export const PaginatedTransactionsDocument = gql`
    query paginatedTransactions($page: Int, $TXType: String) {
  transactions(page: $page, TXType: $TXType) {
    data {
      amount
      senderPublicKey
      senderId
      recipientId
      id
      moduleAssetId
      timestamp
      senderUsername
      recipientUsername
      data
      height
      fee
    }
    pagination {
      total
      currentPage
      from
      to
    }
  }
}
    `;

/**
 * __usePaginatedTransactionsQuery__
 *
 * To run a query within a React component, call `usePaginatedTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTransactionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      TXType: // value for 'TXType'
 *   },
 * });
 */
export function usePaginatedTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedTransactionsQuery, PaginatedTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedTransactionsQuery, PaginatedTransactionsQueryVariables>(PaginatedTransactionsDocument, options);
      }
export function usePaginatedTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedTransactionsQuery, PaginatedTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedTransactionsQuery, PaginatedTransactionsQueryVariables>(PaginatedTransactionsDocument, options);
        }
export type PaginatedTransactionsQueryHookResult = ReturnType<typeof usePaginatedTransactionsQuery>;
export type PaginatedTransactionsLazyQueryHookResult = ReturnType<typeof usePaginatedTransactionsLazyQuery>;
export type PaginatedTransactionsQueryResult = Apollo.QueryResult<PaginatedTransactionsQuery, PaginatedTransactionsQueryVariables>;
export const PaginatedTransactionsByAddressDocument = gql`
    query paginatedTransactionsByAddress($addressContext: String!, $page: Int!) {
  transactionsByAddress(address: $addressContext, page: $page) {
    data {
      id
      height
      moduleAssetId
      nonce
      blockId
      timestamp
      senderPublicKey
      senderId
      recipientId
      amount
      data
      size
      fee
      minFee
      senderUsername
      recipientUsername
    }
    pagination {
      total
      currentPage
      from
      to
    }
  }
}
    `;

/**
 * __usePaginatedTransactionsByAddressQuery__
 *
 * To run a query within a React component, call `usePaginatedTransactionsByAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedTransactionsByAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedTransactionsByAddressQuery({
 *   variables: {
 *      addressContext: // value for 'addressContext'
 *      page: // value for 'page'
 *   },
 * });
 */
export function usePaginatedTransactionsByAddressQuery(baseOptions: Apollo.QueryHookOptions<PaginatedTransactionsByAddressQuery, PaginatedTransactionsByAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedTransactionsByAddressQuery, PaginatedTransactionsByAddressQueryVariables>(PaginatedTransactionsByAddressDocument, options);
      }
export function usePaginatedTransactionsByAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedTransactionsByAddressQuery, PaginatedTransactionsByAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedTransactionsByAddressQuery, PaginatedTransactionsByAddressQueryVariables>(PaginatedTransactionsByAddressDocument, options);
        }
export type PaginatedTransactionsByAddressQueryHookResult = ReturnType<typeof usePaginatedTransactionsByAddressQuery>;
export type PaginatedTransactionsByAddressLazyQueryHookResult = ReturnType<typeof usePaginatedTransactionsByAddressLazyQuery>;
export type PaginatedTransactionsByAddressQueryResult = Apollo.QueryResult<PaginatedTransactionsByAddressQuery, PaginatedTransactionsByAddressQueryVariables>;
export const SearchboxDocument = gql`
    query searchbox($term: String!) {
  search(term: $term) {
    transactions {
      id
    }
    blocks {
      height
      id
    }
    accounts {
      username
      address
    }
  }
}
    `;

/**
 * __useSearchboxQuery__
 *
 * To run a query within a React component, call `useSearchboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchboxQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchboxQuery(baseOptions: Apollo.QueryHookOptions<SearchboxQuery, SearchboxQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchboxQuery, SearchboxQueryVariables>(SearchboxDocument, options);
      }
export function useSearchboxLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchboxQuery, SearchboxQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchboxQuery, SearchboxQueryVariables>(SearchboxDocument, options);
        }
export type SearchboxQueryHookResult = ReturnType<typeof useSearchboxQuery>;
export type SearchboxLazyQueryHookResult = ReturnType<typeof useSearchboxLazyQuery>;
export type SearchboxQueryResult = Apollo.QueryResult<SearchboxQuery, SearchboxQueryVariables>;
export const TransctionByIdDocument = gql`
    query transctionByID($id: String!) {
  transaction(id: $id) {
    amount
    senderPublicKey
    senderId
    recipientId
    id
    timestamp
    moduleAssetId
    senderUsername
    recipientUsername
    data
    height
    fee
    blockId
    blockHeight
    blockTimestamp
    blockGeneratorPublicKey
    blockIsFinal
    blockUsername
    blockAddress
    votes {
      delegateAddress
      delegateUsername
      amount
    }
    multisigRegistration {
      numberOfSignatures
      mandatoryKeys
      optionalKeys
    }
    tokenUnlock {
      delegateAddress
      amount
      unvoteHeight
      username
    }
  }
}
    `;

/**
 * __useTransctionByIdQuery__
 *
 * To run a query within a React component, call `useTransctionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransctionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransctionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTransctionByIdQuery(baseOptions: Apollo.QueryHookOptions<TransctionByIdQuery, TransctionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransctionByIdQuery, TransctionByIdQueryVariables>(TransctionByIdDocument, options);
      }
export function useTransctionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransctionByIdQuery, TransctionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransctionByIdQuery, TransctionByIdQueryVariables>(TransctionByIdDocument, options);
        }
export type TransctionByIdQueryHookResult = ReturnType<typeof useTransctionByIdQuery>;
export type TransctionByIdLazyQueryHookResult = ReturnType<typeof useTransctionByIdLazyQuery>;
export type TransctionByIdQueryResult = Apollo.QueryResult<TransctionByIdQuery, TransctionByIdQueryVariables>;