import React from "react";
import {ReactComponent as AstronautSVG} from "./astronaut.svg";
import {ReactComponent as PlanetSVG} from "./planet.svg";
import "./style.css";
import {Card, CardBody, CardHeader, CardTitle} from "reactstrap";
import {Stars} from "../../UI/Stars";

interface Props {
    title: string
}

export const ComingSoon:React.FC<Props> = ({title}) => {
    return (

        <div className="content">
        <Stars />

        <Card className="card-chart">
            <Stars />
            <CardHeader>
                <CardTitle tag="h4">
                    {title}
                </CardTitle>
                <CardBody style={{textAlign: "center", paddingBottom: "0px"}}>
                        <h2>We are building this page ✨</h2>
                    <div className="space-scene">

                        {/*<div className="cloud cloud1"/>*/}
                        {/*<div className="cloud cloud2"/>*/}
                        {/*<div className="cloud cloud3"/>*/}
                        {/*<div className="cloud cloud4"/>*/}

                        <div className="astronaut">
                            <AstronautSVG />
                        </div>

                        <div className="planet-wrapper">

                            <PlanetSVG/>
                        </div>

                    </div>
                </CardBody>
            </CardHeader>
            {/*<Stars />*/}
        </Card>
            {/*<Stars />*/}

        </div>
    )
}
