import React from "react";
import { Row, Col, Badge } from "reactstrap";
import { beddowsToDecimal } from "../utils/lisk/utils/lisk/beddowsToDecimal";
import { generateAffiliationLabel } from "../utils/delegateAffiliation";
import { delegateLogo } from "../utils/logos/DelegateLogo";
import { delegateStatusColor } from "./utils/colorStatus";
import { Link } from "react-router-dom";
import moment from "moment";
import { MIN_SELF_VOTE_PERCENT } from "../utils/const";

let elementStyle = {
  maxHeight: "4rem",
  padding: "10px",
  borderTop: "#ffffff1f 1px solid",
  color: "rgba(255,255,255,0.7)",
  transition: "all 0.4s ease-out",
  opacity: 1,
};

const avatarStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "25px",
  marginRight: "10px",
};

interface Props {
  rank: string;
  rankAdjusted: string;
  address: string;
  missedBlocks: string;
  producedBlocks: string;
  rewards: string;
  PoM: string[];
  vote: string;
  isBanned: boolean;
  username: string;
  affiliation: string;
  nextForgingTime: string;
  selfVote: number;
  willForge: boolean;
}

export const DelegatesElement: React.FC<Props> = ({
  rank,
  rankAdjusted,
  address,
  missedBlocks,
  producedBlocks,
  rewards,
  PoM,
  vote,
  username,
  isBanned,
  affiliation,
  selfVote,
  nextForgingTime,
  willForge,
}) => {
  const affiliationLabels = generateAffiliationLabel(affiliation);
  let badgeRankColor = willForge ? "info" : "link";
  badgeRankColor = isBanned ? "danger" : badgeRankColor;

  const maxVotes = (+selfVote / MIN_SELF_VOTE_PERCENT) * 100;
  const available = +maxVotes - +vote;
  const usedCapacity = ((available * 100) / maxVotes).toFixed(2);
  const forgingInLabel = isBanned
    ? "❌ Banned"
    : willForge
    ? nextForgingTime
    : "standby";

  return (
    <tr className={`delegate-row ${isBanned ? " is-banned" : ""}`}>
      <th scope="row">
        <Badge
          pill
          // color={parseInt(rank) > 101 ? "link" : "info"}
          color={badgeRankColor}
          className="delegate-badge"
        >
          {rankAdjusted}
        </Badge>
      </th>
      <td>
        {/*<img src={logo} style={avatarStyle} alt="avatar" /> &nbsp;*/}
        {delegateLogo({ delegateName: username, style: avatarStyle })}
        <Link to={`/account/${address}`}>
          <strong>{username}</strong>
        </Link>
      </td>
      <td>{affiliationLabels}</td>
      <td data-tip={+missedBlocks > 0 ? `${missedBlocks} missed blocks` : ""}>
        {delegateStatusColor(rank, isBanned, !!+missedBlocks, willForge)}
        {forgingInLabel}
      </td>
      {/*<td></td>*/}
      <td>{(+beddowsToDecimal(vote)).toLocaleString()} Ⱡ</td>
      <td>{producedBlocks} Blocks</td>
      <td>{PoM.length} Proofs of Misbehavior</td>
      <td
        className={"text-right"}
        data-tip={`${(+beddowsToDecimal(
          available
        )).toLocaleString()} LSK in voting capacity remaining`}
      >
        {isNaN(+usedCapacity) ? "0" : usedCapacity} %
      </td>
    </tr>
  );
};
