import React from "react";
import "./blockRow.css";
import { beddowsToDecimal } from "../utils/lisk/utils/lisk/beddowsToDecimal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { delegateLogo } from "../utils/logos/DelegateLogo";
import { truncateMidString } from "../utils/strings/strings";
import { sanitizeString } from "../utils/strings/censor";
import { Copy } from "../../UI/copy/Copy";

// TODO: duplicated code
const Column = {
  maxWidth: "6rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap" as "nowrap",
};
const avatarStyleVote = {
  width: "25px",
  height: "25px",
  borderRadius: "25px",
  marginRight: "10px",
};

interface Props {
  transactions: any[];
}

export const TransactionsRow: React.FC<Props> = ({ transactions }) => {
  const recipientField = (tx: any) => {
    let content: any = "";

    switch (tx.moduleAssetId) {
      case "2:0":
        let recipientLabel = tx.recipientUsername
          ? tx.recipientUsername
          : tx.recipientId;
        // TODO: ADD ON THE SERVER
        // recipientLabel =
        //   tx.recipient_tag && tx.recipient_tag.tag
        //     ? tx.recipient_tag.tag
        //     : recipientLabel;

        content = (
          <span>
            <Link to={`/account/${tx.recipientId}`}>
              {delegateLogo({
                delegateName: tx.recipientUsername
                  ? tx.recipientUsername
                  : tx.recipientId,
                style: avatarStyleVote,
                address: tx.recipientId,
                generateRandom: true,
              })}
              {truncateMidString(recipientLabel, 15)}
            </Link>{" "}
            <Copy text={tx.recipientId} />
          </span>
        );
        break;
      case "4:0":
        content = "Multisig registration";
        break;
      case "5:0":
        content = "Delegate registration";
        break;
      case "5:1":
        content = "Delegate vote";
        break;
      case "5:2":
        content = "Token unlock";
        break;
      case "5:3":
        content = "Misbehavior report";
        break;
      case "1000:0":
        content = "Legacy address claim";
        break;
      default:
        content = "unsupported transaction";
    }

    return content;
  };

  return (
    <>
      {transactions.map((tx: any) => {
        const hasDataIcon = !!tx.data ? "fas" : "far";
        let senderLabel = tx.senderUsername ? tx.senderUsername : tx.senderId;

        // TODO: ADD ON THE SERVER
        // senderLabel =
        //   tx.sender_tag && tx.sender_tag.tag ? tx.sender_tag.tag : senderLabel;

        return (
          <tr key={tx.id}>
            <td style={{ ...Column }}>
              <FontAwesomeIcon
                data-tip={sanitizeString(tx.data)}
                className="fa"
                icon={[hasDataIcon, "comment"]}
                style={{ marginRight: 12, fontSize: "small" }}
              />
              <Link to={`/transaction/${tx.id}`}>
                {truncateMidString(tx.id)}
              </Link>{" "}
              <Copy text={tx.id} />
            </td>
            <td>
              {moment(tx.timestamp * 1000).format("D/MM/YYYY - HH:mm:ss")}
            </td>
            <td>
              <Link to={`/account/${tx.senderId}`}>
                {delegateLogo({
                  delegateName: tx.senderUsername
                    ? tx.senderUsername
                    : tx.senderId,
                  style: avatarStyleVote,
                  address: tx.senderId,
                  generateRandom: true,
                })}
                {truncateMidString(senderLabel, 20)}
              </Link>{" "}
              <Copy text={tx.senderId} />
            </td>
            <td>{recipientField(tx)}</td>
            <td className="text-center">
              <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
                {beddowsToDecimal(
                  tx.amount > 0 ? tx.amount : 0,
                  2
                ).toLocaleString()}{" "}
                Ⱡ
              </Badge>
            </td>
            <td className="text-center">
              {beddowsToDecimal(tx.fee > 0 ? tx.fee : 0, 2).toLocaleString()} Ⱡ
            </td>
          </tr>
        );
      })}
    </>
  );
};
