import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Row,
  Col,
  Table,
  CardTitle,
  Badge,
} from "reactstrap";
import { TXElement } from "./TXElement";
import { IsErrorOrLoading } from "../../utils/IsErrorOrLoading";
import { useHistory, useParams } from "react-router-dom";
import { AccountDetails } from "./AccountDetails";
import { AccountAlert } from "./AccountAlert";
import { BlockHeightContext } from "../../../UI/layouts/BaseLayout";
import { AccountVotes } from "./AccountVotes";
import { Ghost } from "../../../UI/ghost/Ghost";
import { NoTransactions } from "./noTransactions/NoTransactions";
import {
  SentVotes,
  useAccountInfoQuery,
  usePaginatedTransactionsByAddressQuery,
  usePaginatedTransactionsQuery,
} from "../../../generated/graphql";
import { Pagination } from "../../../UI/pagination/Pagination";

export const Account: React.FC = () => {
  const limit = 10;
  // @ts-ignore
  let { addressContext: addressContextParam, page } = useParams();
  const [addressContextReact, setAddressContextReact] = useState<string>(
    addressContextParam?.toLocaleLowerCase() || ""
  );
  const [pagination, setPagination] = useState(page ? +page : 1);
  const history = useHistory();

  // useEffect(() => {
  //   transactionsRefetch({
  //     addressContext: addressContextReact,
  //     page: pagination,
  //   });
  // }, [pagination]);

  const {
    data: transactionsData,
    loading: transactionsLoading,
    error: transactionsError,
    refetch: transactionsRefetch,
  } = usePaginatedTransactionsByAddressQuery({
    variables: {
      addressContext: addressContextReact,
      page: pagination,
    },
    // fetchPolicy: 'no-cache'
  });
  const {
    data: accountData,
    loading: loadingAccountData,
    error: errorAccountData,
    refetch: accountRefetch,
  } = useAccountInfoQuery({
    variables: {
      addressContext: addressContextReact,
    },
    // fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (pagination > totalPages) {
      console.log("o");
      setPagination(1);
    }
    setPagination(page ? +page : 1);
    setAddressContextReact(addressContextReact);
    accountRefetch({
      addressContext: addressContextReact,
    });
  }, [addressContextReact]);

  const { data: heightData }: any = React.useContext(BlockHeightContext);

  const handlePagination = (newPage: number) => {
    setPagination(newPage);
    history.push(`/account/${addressContextReact}/${newPage}`);
  };

  if (
    transactionsLoading ||
    transactionsError ||
    loadingAccountData ||
    errorAccountData
  ) {
    return (
      <div className="content">
        <IsErrorOrLoading
          error={
            !!errorAccountData || !!transactionsError //||
          }
          title={"transactions"}
        />
        ;
      </div>
    );
  }

  if (!accountData?.account?.address) {
    return (
      <div className="content">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle tag="h4">Account</CardTitle>
          </CardHeader>
          <CardBody>
            {/*<DatailCard title="Transactions">*/}
            <Ghost message={"No account found"} />
            {/*</DatailCard>*/}
          </CardBody>
        </Card>
      </div>
    );
  }

  if (!addressContextReact) {
    return (
      <div className="content">
        <Card className="card-chart">
          <CardHeader>
            <CardTitle tag="h4">Transactions</CardTitle>
            <CardBody>
              <CardTitle tag="h4">
                <Badge className={"badge-dark"} style={{ fontSize: "medium" }}>
                  {addressContextReact}
                </Badge>
                not found in the blockchain :(
              </CardTitle>
            </CardBody>
          </CardHeader>
        </Card>
      </div>
    );
  }

  const transactions = transactionsData?.transactionsByAddress?.data;
  const accountInfo = accountData.account;
  const totalDocs =
    transactionsData?.transactionsByAddress?.pagination?.total || 0;
  const totalPages = Math.ceil(totalDocs / 10);
  const blockHeight = heightData?.lastBlock?.height
    ? heightData.lastBlock.height
    : 0;

  return (
    <>
      <div className="content">
        {accountInfo.dpos?.delegate?.isBanned ? (
          <AccountAlert
            msg={
              "This account has been banned by the consensus, do not delegate to this address"
            }
          />
        ) : null}
        <Row className={"d-block d-sm-none"}>
          <Col md="3">
            <>
              <AccountDetails
                address={accountInfo.address || ""}
                addressTag={
                  /* accountInfo.tag?.tag
                    || */ undefined
                }
                isExchange={/*accountInfo.tag?.is_exchange || */ undefined}
                isScam={/*accountInfo.tag?.is_scam || */ undefined}
                isLiskhq={/*accountInfo.tag?.is_liskhq || */ undefined}
                balance={accountInfo?.token?.balance || "0"}
                lockedBalance={accountInfo?.token?.locked || "0"}
                username={accountInfo?.username || ""}
                publicKey={accountInfo?.publicKey || "unknown"}
                multisig={accountInfo?.keys?.numberOfSignatures || "0"}
                votes={(accountInfo.dpos?.sentVotes as SentVotes[]) || []}
                totalVotesReceived={
                  accountInfo.dpos?.delegate?.totalVotesReceived || "0"
                }
                // secondPublicKey={accountInfo.secondPublicKey}
                // rank={accountInfo.rank}
              />
            </>
          </Col>
        </Row>
        <Row>
          <Col md="9">
            <Card>
              <CardHeader>
                <h5 className="title">Transactions</h5>
              </CardHeader>
              <CardBody></CardBody>
              <CardFooter>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>TXID</th>
                      <th>Date</th>
                      <th>Sender</th>
                      <th>Recipient</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Confirm.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions?.map((tx: any) => {
                      return (
                        <TXElement
                          key={tx.id}
                          id={tx.id}
                          timestamp={tx.timestamp}
                          sender={
                            tx.senderUsername ||
                            // (tx.sender_tag && tx.sender_tag.tag) ||
                            tx.senderId
                          }
                          senderId={tx.senderId}
                          recipient={
                            tx.recipientUsername ||
                            // (tx.recipient_tag && tx.recipient_tag.tag) ||
                            tx.recipientId
                          }
                          recipientId={tx.recipientId}
                          amount={tx.amount}
                          fee={tx.fee}
                          transferData={tx.data}
                          confirmations={blockHeight - +tx.height}
                          senderAffiliation={""}
                          recipientAffiliation={""}
                          type={tx.moduleAssetId}
                          addressContext={addressContextReact}
                          setAddressContextReact={setAddressContextReact}
                        />
                      );
                    })}
                  </tbody>
                </Table>
                {!transactions?.length ? (
                  <NoTransactions balance={accountInfo.token?.balance || "0"} />
                ) : null}
                <Row>
                  <Col md={12}>
                    <Pagination
                      page={pagination}
                      totalPages={totalPages}
                      setPage={handlePagination}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>

          <Col md="3" className={"d-none d-md-block"}>
            <AccountDetails
              address={accountInfo.address || ""}
              addressTag={
                /* accountInfo.tag?.tag
                    || */ undefined
              }
              isExchange={/*accountInfo.tag?.is_exchange || */ undefined}
              isScam={/*accountInfo.tag?.is_scam || */ undefined}
              isLiskhq={/*accountInfo.tag?.is_liskhq || */ undefined}
              balance={accountInfo?.token?.balance || "0"}
              lockedBalance={accountInfo?.token?.locked || "0"}
              username={accountInfo?.username || ""}
              publicKey={accountInfo?.publicKey || "unknown"}
              multisig={accountInfo?.keys?.numberOfSignatures || "0"}
              votes={(accountInfo.dpos?.sentVotes as SentVotes[]) || []}
              totalVotesReceived={
                accountInfo.dpos?.delegate?.totalVotesReceived || "0"
              }
            />
            <AccountVotes
              votes={(accountInfo.dpos?.sentVotes as SentVotes[]) || []}
              setAddressContextReact={setAddressContextReact}
            />
          </Col>
        </Row>
        <Row className={"d-md-none"}>
          <Col xs={12}>
            <AccountVotes
              votes={(accountInfo.dpos?.sentVotes as SentVotes[]) || []}
              setAddressContextReact={setAddressContextReact}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
