import React from "react";

export const Stars: React.FC = () => {
  return (
    <>
      <div id="stars" />
      <div id="stars2" />
      <div id="stars3" />
    </>
  );
};
