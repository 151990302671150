import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import ReactTooltip from "react-tooltip";
import React from "react";

const elementStyle = {
  // maxHeight: "4rem",
  padding: "10px",
  borderTop: "#ffffff1f 1px solid",
};

export const NoTransactions = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4">Last Transactions</CardTitle>
      </CardHeader>
      <CardBody>
        <Row
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
            maxHeight: "25rem",
            overflowY: "auto",
          }}
        >
          <Col md={12}>
            <Row style={{ ...elementStyle }}>
              <Col md={12}>
                <Row>
                  <Col md={12} style={{ paddingLeft: 0 }}>
                    No transactions
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
