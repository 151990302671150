import React from "react";
import { NumberCard } from "../../../UI/components/NumberCard";
import { useQuery } from "@apollo/react-hooks";
import { BLOCK_HEIGHT_QUERY } from "../../../apollo/queries";

export const TotalSupplyCard: React.FC = () => {
  const { data, loading, error } = useQuery(BLOCK_HEIGHT_QUERY, {
    fetchPolicy: "cache-only",
  });

  const blockHeight = data?.lastBlock?.height ? data.lastBlock.height : 0;
  const supply = +blockHeight * 5;
  const readableSupply = supply.toLocaleString();

  return (
    <>
      <NumberCard
        data={`${readableSupply} Ⱡ`}
        icon={"fas fa-angle-double-up"}
        title={"Total Supply"}
        loading={loading}
        error={!!error}
      />
    </>
  );
};
