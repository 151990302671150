import React from "react";
import "./style.css";
import {Row} from "reactstrap";

interface Props {
    balance: string
}

export const NoTransactions:React.FC<Props> = ({balance}) => {
    const label = !!balance ? 'This account has no transactions, it has only forged blocks so far...'  : 'No transactions found for this account';
    return (
        <>
        <Row style={{margin: "30px"}}>
            <div className="ship">
                <div className="body">
                    <div className="eyes">
                        <span className="eye_1"></span>
                        <span className="eye_2"></span>
                    </div>
                    <svg className="vawes" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                         viewBox="0 0 50 50">
                        <defs>
                            <circle id="wave" cx="25" cy="25" r="24"/>
                        </defs>
                        {/*// @ts-ignore*/}
                        <use xlinkHref="#wave" className="wave_1"/>
                        {/*// @ts-ignore*/}
                        <use xlinkHref="#wave" className="wave_2"/>
                    </svg>
                </div>
                <div className="foot_1"></div>
                <div className="foot_2"></div>
                <div className="foot_3"></div>
            </div>
        </Row>
            <Row className={"text-center top45"}>
                <h4 style={{width: "100%"}}>{label}</h4>
            </Row>
        </>
    )
}
