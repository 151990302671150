import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Button as ButtonReactStrap,
  Collapse as CollapseReactStrap,
  DropdownToggle as DropdownToggleReactStrap,
  DropdownMenu as DropdownMenuReactStrap,
  DropdownItem as DropdownItemReactStrap,
  UncontrolledDropdown as UncontrolledDropdownReactStrap,
  InputGroup as InputGroupReactStrap,
  Navbar as NavbarReactStrap,
  NavLink as NavLinkReactStrap,
  Nav as NavReactStrap,
  Container as ContainerReactStrap,
  Badge,
} from "reactstrap";
import logo from "../assets/lisk-logo.png";
import { SearchModal } from "./SearchModal";
import { Spinner } from "../spinner/Spinner";
import { BlockHeightContext, TickerContext } from "../layouts/BaseLayout";
import { useLastTicksQuery } from "../../generated/graphql";
import { CURRENCY_PAIRS, CURRENCY_BASE } from "../components/chartBanner/const";

const dotStatus = {
  width: "10px",
  height: "10px",
  borderRadius: "26px",
  marginTop: 6,
};
// duplicated code
const redStatus = {
  ...dotStatus,
  backgroundColor: "#FC78A3",
};
const greenStatus = {
  ...dotStatus,
  backgroundColor: "rgb(0 191 76)",
};
const unknownStatus = {
  ...dotStatus,
  borderColor: "rgb(191, 115, 0)",
  // borderStyle: "dashed"
};

export const Navbar: React.FC<any> = ({
  brandText,
  toggleSidebar,
  sidebarOpened,
}) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [modalSearch, setModalSearch] = useState(false);
  const [color, setColor] = useState("navbar-transparent");
  const {
    data: pricesData,
    loading: pricesLoading,
    error: pricesError,
  } = useLastTicksQuery({
    pollInterval: 60000,
  });
  const { data, loading, error }: any = React.useContext(BlockHeightContext);
  const ticker = React.useContext(TickerContext);
  const [currency, setCurrency] = useState<CURRENCY_PAIRS>(
    (ticker as CURRENCY_PAIRS) || CURRENCY_PAIRS.LSKUSD
  );
  useEffect(() => {
    if (ticker) {
      setCurrency(ticker);
    }
  }, [ticker]);

  const prices = pricesData?.lastTicks;

  const blockchainStatusColor =
    !!loading || !!error
      ? !!loading
        ? unknownStatus
        : redStatus
      : greenStatus;
  const blockHeight = data?.lastBlock?.height ? data.lastBlock.height : 0;

  useEffect(() => {
    window.addEventListener("resize", updateColor);

    return () => {
      window.removeEventListener("resize", updateColor);
    };
  });

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-white");
    } else {
      setColor("navbar-transparent");
    }
  };

  // this function opens and closes the collapse on small devices
  const toggleCollapse = () => {
    if (collapseOpen) {
      setColor("navbar-transparent");
    } else {
      setColor("bg-white");
    }
    setCollapseOpen(!collapseOpen);
  };

  // this function is to open the SearchCard modal
  const toggleModalSearch = () => {
    setModalSearch(!modalSearch);
  };

  return (
    <>
      <NavbarReactStrap
        className={classNames("navbar-absolute", color)}
        expand="lg"
      >
        <ContainerReactStrap fluid>
          <div className="navbar-wrapper">
            <div
              className={classNames("navbar-toggle d-inline", {
                toggled: sidebarOpened,
              })}
            >
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleSidebar}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            {/*<NavbarBrandReactStrap href="#pablo" onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault()}>*/}
            {brandText}
            {/*</NavbarBrandReactStrap>*/}
          </div>
          <button
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navigation"
            data-toggle="collapse"
            id="navigation"
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </button>
          <CollapseReactStrap navbar isOpen={collapseOpen}>
            <NavReactStrap className="ml-auto" navbar>
              <InputGroupReactStrap>
                <div style={{ marginTop: 14 }}>
                  <Badge
                    className={"badge-dark"}
                    style={{
                      fontSize: "small",
                      fontWeight: 400,
                      display: "inline",
                    }}
                  >
                    {pricesLoading || pricesError ? (
                      pricesLoading ? (
                        "Loading ticker"
                      ) : (
                        "Ticker error"
                      )
                    ) : (
                      <>
                        💎 1 LSK :{" "}
                        <span>{(prices && prices[currency]) || -1}</span>{" "}
                        {CURRENCY_BASE[currency]}
                      </>
                    )}
                  </Badge>
                </div>
              </InputGroupReactStrap>
              <InputGroupReactStrap className="search-bar">
                <div
                  // color="link"
                  // data-target="#searchModal"
                  // data-toggle="modal"
                  // id="search-button"
                  // onClick={toggleModalSearch}
                  style={{ marginTop: 14 }}
                >
                  <Badge
                    className={"badge-dark"}
                    style={{
                      fontSize: "small",
                      fontWeight: 400,
                      display: "inline",
                    }}
                  >
                    <div
                      className="float-left mr-2"
                      style={blockchainStatusColor}
                    />
                    {process.env.REACT_APP_NETWORK} blockheight:{" "}
                    {!!blockHeight ? blockHeight : <Spinner />}
                  </Badge>
                </div>
              </InputGroupReactStrap>
              <InputGroupReactStrap className="search-bar">
                <ButtonReactStrap
                  color="link"
                  data-target="#searchModal"
                  data-toggle="modal"
                  id="search-button"
                  onClick={toggleModalSearch}
                >
                  <i className="tim-icons icon-zoom-split" />
                  <span className="d-lg-none d-md-block">Search</span>
                </ButtonReactStrap>
              </InputGroupReactStrap>
              {/*<UncontrolledDropdownReactStrap nav>*/}
              {/*  <DropdownToggleReactStrap*/}
              {/*    caret*/}
              {/*    color="default"*/}
              {/*    data-toggle="dropdown"*/}
              {/*    nav*/}
              {/*  >*/}
              {/*    <div className="notification d-none d-lg-block d-xl-block" />*/}
              {/*    <i className="tim-icons icon-sound-wave" />*/}
              {/*    <p className="d-lg-none">Notifications</p>*/}
              {/*  </DropdownToggleReactStrap>*/}
              {/*  <DropdownMenuReactStrap className="dropdown-navbar" right tag="ul">*/}
              {/*    <NavLinkReactStrap tag="li">*/}
              {/*      <DropdownItemReactStrap className="nav-item">*/}
              {/*        Mike John responded to your email*/}
              {/*      </DropdownItemReactStrap>*/}
              {/*    </NavLinkReactStrap>*/}
              {/*    <NavLinkReactStrap tag="li">*/}
              {/*      <DropdownItemReactStrap className="nav-item">*/}
              {/*        You have 5 more tasks*/}
              {/*      </DropdownItemReactStrap>*/}
              {/*    </NavLinkReactStrap>*/}
              {/*    <NavLinkReactStrap tag="li">*/}
              {/*      <DropdownItemReactStrap className="nav-item">*/}
              {/*        Your friend Michael is in town*/}
              {/*      </DropdownItemReactStrap>*/}
              {/*    </NavLinkReactStrap>*/}
              {/*    <NavLinkReactStrap tag="li">*/}
              {/*      <DropdownItemReactStrap className="nav-item">*/}
              {/*        Another notification*/}
              {/*      </DropdownItemReactStrap>*/}
              {/*    </NavLinkReactStrap>*/}
              {/*    <NavLinkReactStrap tag="li">*/}
              {/*      <DropdownItemReactStrap className="nav-item">*/}
              {/*        Another one*/}
              {/*      </DropdownItemReactStrap>*/}
              {/*    </NavLinkReactStrap>*/}
              {/*  </DropdownMenuReactStrap>*/}
              {/*</UncontrolledDropdownReactStrap>*/}
              <UncontrolledDropdownReactStrap nav>
                <DropdownToggleReactStrap
                  caret
                  color="default"
                  data-toggle="dropdown"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <div className="photo">
                    <img alt="..." src={logo} />
                  </div>
                  <b className="caret d-none d-lg-block d-xl-block" />
                  {/*<p className="d-lg-none">Log out</p>*/}
                </DropdownToggleReactStrap>
                <DropdownMenuReactStrap
                  className="dropdown-navbar"
                  right
                  tag="ul"
                >
                  <NavLinkReactStrap tag="li">
                    <DropdownItemReactStrap className="nav-item">
                      Lisk sidechains will be listed here
                    </DropdownItemReactStrap>
                  </NavLinkReactStrap>
                  <NavLinkReactStrap tag="li">
                    {/*  <DropdownItemReactStrap className="nav-item">Settings</DropdownItemReactStrap>*/}
                    {/*</NavLinkReactStrap>*/}
                    {/*<DropdownItemReactStrap divider tag="li" />*/}
                    {/*<NavLinkReactStrap tag="li">*/}
                    {/*  <DropdownItemReactStrap className="nav-item">Log out</DropdownItemReactStrap>*/}
                  </NavLinkReactStrap>
                </DropdownMenuReactStrap>
              </UncontrolledDropdownReactStrap>
              <li className="separator d-lg-none" />
            </NavReactStrap>
          </CollapseReactStrap>
        </ContainerReactStrap>
      </NavbarReactStrap>
      <SearchModal
        modalSearch={modalSearch}
        toggleModalSearch={toggleModalSearch}
      />
    </>
  );
};
